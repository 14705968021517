var Nimb = Nimb || {};

Nimb.UnSlider = function () {
    var $ImageRatio = 2.293941;
    var $resized = false;


    function init() {
        var calculatedHeight = $('.oneclm-facility-section.variant--wide').first().width()  / $ImageRatio;

        $('.oneclm-facility-section.variant--wide .hide-for-large-up img').css("max-height", calculatedHeight + "px");

        $(window).smartresize(function () {
            if (!$resized) {
                $('.oneclm-facility-section.variant--wide .hide-for-large-up img').css("max-height", "none");
                $resized = true;
            }
        });
    }

    $(document).ready(function () {
        init();
    });

    $(document).on("jsonLoaded", function () {
        init();
    });
}();