var Tivoli = Tivoli || {};

Tivoli.textExpand = function () {


    function init() {
        if ($('.oneclm-facility-section .text-box').length === 0) {
            return;
        }
        setupButtons();
        setHeight();

        $(window).smartresize(function () {
            setHeight();
        });
    }

    function setupButtons() {
        $('.oneclm-facility-section .text-box').each(function () {
            var textBox = $(this);

            $(this).find('.js-facility').click(function () {
                textBox.addClass('js-show-secondary-content');
            });

            var textBox = $(this);
            $(this).find('.js-facility-close').click(function () {
                textBox.removeClass('js-show-secondary-content');
            });
        });
        
    }

    function setHeight() {
        $('.oneclm-facility-section .text-box').each(function () {
            var primaryContent = $(this).find('.primary-content'),
            secondaryContent = $(this).find('.secondary-content'),
            secondaryContentHeight = secondaryContent.height();

            primaryContent.height(secondaryContentHeight);
        });
    }

    $(document).ready(function () {
        init();
    });
}();