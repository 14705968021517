var Tivoli = Tivoli || {};

Tivoli.LoadContent = function () {
    var $loadJson = $('.load-json'),
        $contentContainer = $('.content-container');


    function init() {
        if ($loadJson.length === 0) {
            return;
        }

        $contentContainer.load(window.location.href + "?json=1", function () {
            $(document).trigger("jsonLoaded");
        });
    }

    $(document).ready(function () {
        init();
    });
}();