var Nimb = Nimb || {};

Nimb.radiobuttons = function () {
	$.fn.customRadiobutton = function() {
		var domElements = '<div><span class="unchecked"></span><span class="checked"></span></div>';

		this.each(function() {

			var radioButton = $(this);

			radioButton.addClass('custom-radiobutton');
			radioButton.after(domElements);

			// Detech amount of text in label
			if(radioButton.siblings('div').height() < radioButton.siblings('label').height()) {
				radioButton.parent().addClass('label-overflow');
			};

			// On click on "radio" imitator
			radioButton.siblings('div').on('click touchsend', function(e) {
				var input = $(this).siblings(':radio');
				e.stopPropagation();
				e.preventDefault();
				clickHandler(input);
			});

			// On click on "radio" / or label
			function clickHandler(input) {
				if (!input.prop('checked')) {
					input.closest('label').addClass('active');
					input.prop('checked', true);

				}
				input.change();
			}

		});
	};

	function init() {
		//$(':radio').customRadiobutton();
        $(':radio').not('.preventCustomRadio').customRadiobutton();
	}

	$(document).ready(function () {
		init();
	});
}();