var Tivoli = Tivoli || {};
Tivoli.Shop = Tivoli.Shop || {};

Tivoli.Shop.Basket = function () {

    function init() {
        reload(true);
    }

    function reload(firstLoad) {
        setupChangeQuantity();
        setupRemoveOrderLine();
        setupBuyProduct();
        setupMembershipDiscountHandling();
        validateTicketsOnHold();
        if(!firstLoad) {
            Tivoli.validation.forceInit($(".js-basket-membership"));
        }
    }

    function setupChangeQuantity() {
        $(".js-order-line-quantity").each(function() {
            var quantityContainer = $(this);

            var chooser = quantityContainer.find(".js-order-line-quantity-chooser");
            var decrementer = quantityContainer.find(".js-order-line-quantity-decrement");
            var incrementer = quantityContainer.find(".js-order-line-quantity-increment");

            var startValue = parseInt(chooser.val());
            var minValue = quantityContainer.data("min");
            var maxValue = quantityContainer.data("max");

            var chosenQuantity = startValue;
            var changeQuantityTimer = null;

            function chooseNewQuantity(newQuantity, oldQuantity) {

                if (newQuantity < minValue) {
                    newQuantity = minValue;
                }
                if (newQuantity > maxValue) {
                    newQuantity = maxValue;
                }

                if (newQuantity == minValue) {
                    decrementer.addClass("disabled");
                } else {
                    decrementer.removeClass("disabled");
                }
                if (newQuantity == maxValue) {
                    incrementer.addClass("disabled");
                } else {
                    incrementer.removeClass("disabled");
                }

                chooser.val(newQuantity);

                if (newQuantity === startValue) {
                    return;
                }

                chosenQuantity = newQuantity;

                // Wait and see if other changes happen, before going to the server
                if (changeQuantityTimer) {
                    clearTimeout(changeQuantityTimer);
                }

                changeQuantityTimer = setTimeout(function() {
                    changeQuantity(quantityContainer, newQuantity, oldQuantity);
                }, 300);
            }

            decrementer.on("click", function() {
                chooseNewQuantity(chosenQuantity - 1, startValue);
            });
            incrementer.on("click", function () {
                chooseNewQuantity(chosenQuantity + 1, startValue);
            });

            chooser.keypress(function (e) {
                if (e.which == 13) {
                    chooser.blur();
                }
            });
            chooser.on("blur", function() {
                chooseNewQuantity(parseInt(chooser.val()), startValue);
            });
        });
    }

    function changeQuantity(quantityContainer, newQuantity, oldQuantity) {
        var orderLine = quantityContainer.parents(".js-order-line");
        if (orderLine.length > 0) {
            var id = orderLine.data("id");
            handleGoogleAnalyticsEvents(orderLine, newQuantity, oldQuantity);
            callServerAndUpdateBasket("ChangeOrderLineQuantity", { orderLineId: id, newQuantity: newQuantity, currentItemPath: Tivoli.getCurrentItemPath() }, null, null);
            try{
                var trackingUpdate = {
                    productName : orderLine.data('ga-product-name'),
                    productId : orderLine.data('ga-product-id'),
                    quantityChange: newQuantity-oldQuantity
                }
                Tivoli.Shop.Tracking.pushDigitalDataCartQuantityUpdate([trackingUpdate]);
            }catch(err){}
        }
    }

    function setupRemoveOrderLine() {
        $(".js-order-line-remove").on("click", function() {
            var orderLine = $(this).parents(".js-order-line");

            if (orderLine.length > 0) {
                var id = orderLine.data("id");
                
                var chooserValue = parseInt(orderLine.find(".js-order-line-quantity-chooser").val());
                handleGoogleAnalyticsEvents(orderLine, 0, chooserValue);
                callServerAndUpdateBasket("RemoveOrderLine", { orderLineId: id, currentItemPath: Tivoli.getCurrentItemPath() }, null, null);
            }
        });
    }

    function setupBuyProduct() {
        $(".js-basket-buy-button").on("click", function (e) {
            e.preventDefault();

            var button = $(this);

            var product = button.parents(".js-basket-product");

            var id = product.data("product-id");
            var quantity = product.data("quantity");

            callServerAndUpdateBasket("BasketBuyProduct", { productId: id, quantity: quantity, currentItemPath: Tivoli.getCurrentItemPath() }, null, null);
        });
    }

    function setupMembershipDiscountHandling() {
        var form = $(".js-basket-membership");

        form.on("form-success", function (e, data) {
            setLoadingState(false);
            var basketView = data.BasketView;
            if (basketView) {
                $(".js-basket-container").replaceWith(basketView);
                reload();
            }
        });

        form.on("form-error", function (e, data) {
            setLoadingState(false);
        });

        form.on("form-request", function () {
            setLoadingState(true);
        });
    }


    function validateTicketsOnHold() {
        $(".js-basket-validate").on("click", function (e) {
            e.preventDefault();
            var buyButton = $(this);
            var loader = buyButton.find(".js-circle-loader");
            Tivoli.CircleLoader.loadInitial((loader), null);

            // Add in-progress class to button
            buyButton.addClass('button--in-progress');

            $.ajax({
                url: "/WebShop/Basket/ValidateTicketsOnHold",
                traditional: true,
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                error: function (err) {
                    Tivoli.CircleLoader.reset(loader);
                },
                success: function (data) {
                    if (data.Success) {
                        Tivoli.CircleLoader.loadFinish(loader, function () {
                            window.location.href = buyButton.attr("href");
                        });
                    } else {
                        Tivoli.CircleLoader.reset(loader);
                        reload();
                        showBasketNotifications(data.ErrorMessage);
                    }
                },
                type: 'POST'
            });
        });
    }

    function showBasketNotifications(message) {
        $(".shop-notifications span").html(message);
        $(".shop-notifications").show();
        window.location.hash = "info";
    }

    function setLoadingState(show) {
        if (show) {
            $('.large-basket-container .row').addClass('loader-running');
            $('.large-basket-container').append('<div class="load-overlay"></div>');
        } else {
            $('.large-basket-container .row').removeClass('loader-running');
            $('.load-overlay').remove();
        }
    }

    function callServerAndUpdateBasket(action, dto, onSuccess, onError) {
        setLoadingState(true);

        $.ajax({
            url: "/WebShop/Basket/" + action,
            traditional: true,
            data: JSON.stringify(dto),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            error: function (err) {
                setLoadingState(false);
                if (typeof onError === "function") {
                    onError(err);
                }
                console.log(err);
            },
            success: function (data) {
                var basketContainer = $(".js-basket-container");
                if (basketContainer.length === 0) {
                    if (typeof onError === "function") {
                        onError("");
                    }
                }

                if (typeof onSuccess === "function") {
                    // Success method returns markup to reload
                    data = onSuccess(data);
                    if (!data) {
                        setLoadingState(false);
                        return;
                    }
                }
                setLoadingState(false);
                basketContainer.replaceWith(data);
                reload();
            },
            type: 'POST'
        });
    }

    function handleGoogleAnalyticsEvents(product, newQuantity, oldQuantity) {
        var addToBasket = false;
        if(newQuantity > oldQuantity) {
            gaQuantity = newQuantity - oldQuantity;
            addToBasket = true;
        } else {
            gaQuantity = oldQuantity - newQuantity;
        }
        
        var googleAnalyticsProducts = [];

        var gaMainProduct = {
            'name' : product.data('ga-product-name'),
            'id' : product.data('ga-product-id'),
            'price': product.data('ga-product-price'),
            'category': product.data('ga-product-category'),
            'quantity': gaQuantity
        }
        googleAnalyticsProducts.push(gaMainProduct);

        //Find related products on removeOrderline or if quantity is locked to parent
        var parentId = product.data('id-parent');
        if(parentId != "") {
            var relatedProducts = $('[data-id-related="'+ parentId+ '"]');
            if(relatedProducts.length > 0) {
                $.each( relatedProducts, function( index, value ){
                    var quantityLockedToParent = $(this).data('quantity-locked-to-parent') == true;
                    if(newQuantity == 0 || quantityLockedToParent) 
                    {
                        var relatedQuantity = parseInt($(this).find(".js-order-line-quantity-chooser").val());

                        if(quantityLockedToParent) {
                            relatedQuantity = gaQuantity;
                        }

                        if(relatedQuantity != 0 ) {
                            var gaRelatedProduct = {
                                'name' : $(this).data('ga-product-name'),
                                'id' : $(this).data('ga-product-id'),
                                'price': $(this).data('ga-product-price'),
                                'category': $(this).data('ga-product-category'),
                                'quantity': relatedQuantity
                            }
                    
                            googleAnalyticsProducts.push(gaRelatedProduct);
                        }
                    }
                });
            }
        }

        if(addToBasket) {
            googleAnalyticsAddToBasketEvent(googleAnalyticsProducts);
        } else {
            googleAnalyticsRemoveFromBasketEvent(googleAnalyticsProducts);
        }
        
    }
    
    function googleAnalyticsAddToBasketEvent(products) {
        dataLayer.push({
            event: 'addToCartTivoliDk',
            ecommerce: {
                add: {
                    actionField: {
                        list: 'basketpage', // -> ’produktoversigt’ eller ’produktside’ eller ’kurvsiden’ 
                        dimension4: 'Tivoli.dk', // fast værdi -> skelne mellem tivoli.dk og billetlugen etc.
                    },
                    'products':  products
                }
            }
        });

    }

    function googleAnalyticsRemoveFromBasketEvent(products) {
        dataLayer.push({
            event: 'removeFromCartTivoliDk',
            ecommerce: {
                remove: {
                    actionField: {
                        list: 'basketpage', // -> ’produktoversigt’ eller ’produktside’ eller ’kurvsiden’ 
                        dimension4: 'Tivoli.dk', // fast værdi -> skelne mellem tivoli.dk og billetlugen etc.
                    },
                    'products':  products
                }
            }
        });

    }

    $(document).ready(function () {
        init();
    });

    return {
        reload: reload
    };
}();