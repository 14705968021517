var Tivoli = Tivoli || {};

Tivoli.boxModuleInteractions = function(){
	"use strict";

  	var isAnyTouch = Tivoli.vars.isAnyTouchDevice;
	
	function init(){
		if($(".box-full, .box-thumb, .box-product, .TeaserBoxMedium").length === 0) {
			return;
		}

		windowChange();
		boxInfoToggle();
		boxClickExtension();

		$('.box-product').find('.info-link').on('click', function(e) {
			e.preventDefault();
			if($(this).closest('.swiper').hasClass('js-boxes-open')) {
				$(this).closest('.swiper').removeClass('js-boxes-open');
			} else {
				$(this).closest('.swiper').addClass('js-boxes-open');
			}
		});	

	}

	// BOX PRODUCT INIT
//////
	//

	// INIT OR DESTROY HOVER
	function windowChange() {
		$(window).smartresize(function() {
			boxInit();
		});

		boxInit();
	}


  	// INIT BOX with EVENT-types
	function boxInit() {
		var box			= $('.box-full, .box-thumb'),
			teaserBox	= $('.TeaserBoxMedium__wrapper'),
			infoLink	= $('.info-link');

		teaserBox.each(function() {
			var $boxContent		= $(this).find(".TeaserBoxMedium__content"),
				$boxImage		= $(this).find(".TeaserBoxMedium__backgroundImage"),
				contentHeight	= $boxContent.outerHeight();

			$boxImage.css({
				height: "calc(100% - " + contentHeight + "px)"
			})
		})

		if (isAnyTouch) {
		    infoLink.show();
			if(teaserBox.length > 0) {
				hideBoxTeaserInfo(teaserBox)
			} else {
				hideBoxInfo(box);
			}
		} else {
			boxHoveringCall();
		}
	}

	// CLICK TOGGLE INFO BOX
	function boxInfoToggle() {
	    var box 			= $('.box-item').find('.box-full, .box-thumb, .box-product'),
			teaserBox		= $('.TeaserBoxMedium').find('.TeaserBoxMedium__wrapper'),
			boxToggle		= teaserBox.length > 0 ? teaserBox.find('.info-link') : box.find('.info-link');
		
		boxToggle.unbind('click').on('click', function (e) {
			e.preventDefault();
			boxInfoToggleControl($(this));
		});
	}

	function boxInfoToggleControl(toggle) {
	    var box			= toggle.closest('.box-full, .box-thumb, .box-product'),
			teaserBox	= toggle.closest('.TeaserBoxMedium__wrapper');

		if (teaserBox.length > 0) {
			if (teaserBox.hasClass('is-active')) {
				hideBoxTeaserInfo(teaserBox);
			} else {
				showBoxTeaserInfo(teaserBox);
			}
		} else {
			if(box.hasClass('is-active')) {
				hideBoxInfo(box);
			} else {
				showBoxInfo(box);
			}
		}
	}

	// HOVER TOGGLE INFO BOX
	function boxHoveringCall() {
	    var box = $('.box-full, .box-thumb, .box-product'),
			teaserBox = $(".TeaserBoxMedium__content");


		if (teaserBox.length > 0) {
			teaserBox.unbind('mouseenter').on('mouseenter', function(e) {
				e.stopPropagation();
				showBoxTeaserInfo($(this))		
				
			}).on('mouseleave', function(e) {
				e.stopPropagation();
				hideBoxTeaserInfo($(this))					
			});

		} else {
			box.unbind('mouseenter').on('mouseenter', function(e) {
				e.stopPropagation();
	
				showBoxInfo($(this));
				
			}).on('mouseleave', function(e) {
				e.stopPropagation();
	
				var boxControls = $(this).find('.box-controls li');
		
				hideBoxInfo($(this));				
	
				if(boxControls.length > 0) {
					hideBoxExtension(boxControls);
				}
			});
		}
	}

	/*

	 // WORK IN PROGRESS: calc all animHeights before hovering
	 var boxAnimHeight = [];


	 function calcBoxAnimHeights() {
	 var box 			= $('.box-full, .box-thumb, .box-product');

	 for (var i = 0; box.length > i; i++) {
	 var boxHeader		= box.eq(i).find('.box-content-header').outerHeight(),
	 boxBody			= box.eq(i).find('.box-content-body'),
	 boxControls     = box.eq(i).find('.box-controls'),
	 animHeight      = box.eq(i).outerHeight() + boxBody.outerHeight() - (boxHeader + 15 + boxControls.height());

	 boxAnimHeight.push(animHeight);
	 //console.log(animHeight);
	 }
	 }*/

	// BOX ANIMATION
    // SHOW BOX
	function showBoxInfo(box) {
		var boxBody			= box.find('.box-content-body'),
			gradient		= box.find('.gradient-effect'),
			footer          = box.find('.box-content-footer'),
		    product         = box.find('.box-product'),
			boxControls     = box.find('.box-controls'),
			boxExtension 	= box.find('.js-box-control');

		var boxHeader		= box.find('.box-content-header').outerHeight(),
			animHeight      = box.outerHeight() - (20 + boxHeader + boxControls.outerHeight());


		box.addClass('is-active');

		if(box.is('.box-thumb')) {
			var boxImg = box.find('.js-box-media-adapter');
			boxImg.css('min-height', boxImg.outerHeight());
		}

		boxBody.css({
			minHeight: animHeight,
			maxHeight: "auto",
			opacity: 1
		});
		gradient.css('opacity', '1');
	}

	function showBoxTeaserInfo(box) {
		var boxBody			= box.find('.TeaserBoxMedium__body');
		box.addClass('is-active');

		boxBody.slideDown();
		
		boxBody.css({
			opacity: 1
		});
	}

	// HIDE BOX
	function hideBoxInfo(box) {
		var boxBody		= box.find('.box-content-body'),
			gradient	= box.find('.gradient-effect');
	
		if(box.is('.box-thumb')) {
			var boxImg = box.find('.js-box-media-adapter');
			setTimeout(function() {
				boxImg.css('min-height', '');
			}, 400);

		}

		// REMOVE delay from OPACITY animation for optimal animation-feel
		boxBody.css({
			transition: 'min-height 0.3s cubic-bezier(.8, 0, .2, 1), opacity 0.2s 0s linear',
			opacity: '0'
		});

		gradient.removeAttr('style');		

	  	setTimeout(function() {
		  boxBody.removeAttr('style');
		}, 250);
	}

	// HIDE BOX
	function hideBoxTeaserInfo(box) {
		var boxBody		= box.find('.TeaserBoxMedium__body');

		box.removeClass('is-active');
		boxBody.slideUp();
		
		boxBody.css({
			opacity: 0
		});
	
	  	setTimeout(function() {
		  boxBody.removeAttr('style');
		}, 250);
	}

    function boxClickExtension() {
		var boxLinkTrigger 	= $('.js-box-control'),
			boxLink 		= boxLinkTrigger.find('> a');

		boxLinkTrigger.unbind('mouseenter').on('mouseenter', function (e) {
			e.preventDefault();
			showBoxInfo($(this).closest('.box-thumb'));
			showBoxExtension($(this).closest('li'));
		    return;
		});

		boxLinkTrigger.on('mouseleave', function() {
			hideBoxExtension($(this).closest('li'));

			// IF MOUSE LEAVE IS ON THE BOX, OPEN IT
			if($(this).closest('.box-thumb').find(":hover").length > 0) {
				showBoxInfo($(this).closest('.box-thumb'));
			}
		});
	}

	// BOX CONTROLS ANIMATIONS
	function showBoxExtension($this) {
		var boxExtension 		= $this.find('.js-box-extension'),
			body 				= $this.closest('.box-content-inner').find('.box-content-body'),
			header 				= $this.closest('.box-content-inner').find('.box-content-header'),
			listHeight			= ($this.find('ul li').length * $this.find('ul li.special-dropdown').height() - 9) * (-1);

		if($this.find('ul li.special-dropdown').height() > 60){
			$this.find('ul li.special-dropdown').addClass('special-dropdown--align-left');
		} else {
			$this.find('.special-dropdown--align-left').removeClass('special-dropdown--align-left');
		}

		$this.addClass('is-active');

		boxExtension.css({
			top: listHeight
		});
		$this.closest('.box-content').find('.box-content-header').css('opacity', '0.2');
	}

    
	function hideBoxExtension($this) {
		var boxExtension 	= $this.find('.js-box-extension'),
			body 			= $this.closest('.box-content-inner').find('.box-content-body'),
			header 			= $this.closest('.box-content-inner').find('.box-content-header');

		$this.removeClass('is-active');

		boxExtension.removeAttr('style');
		$this.closest('.box-content').find('.box-content-header').removeAttr('style');
	}

	$(document).ready(function () {
	    init();
	});
}();