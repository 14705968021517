var Nimb = Nimb || {};

Nimb.marquee = function () {

	var outletWidth = 340;
	var pagePadding = 20;
	var delay = 1;
    var notification = $('.js-notification');
	var menuTitleAnimArea;

	var menuTitleAnimWidth;
	var	lengthOfAnimation;
	var animationTiming;

	// SPECIFIC FOR MENU HEADLINES

	function init() {

	    $(notification).mouseleave(function () {
		    menuTitleAnimArea = $(this).find('.js-notification-overflow-anim');
			resetMarquee();
		});
	    $(notification).mouseenter(function () {
		    menuTitleAnimArea = $(this).find('.js-notification-overflow-anim');
		    updateVars();
		    TestMenuForMarquee();
		});
	}

	function updateVars() {
	    menuTitleAnimWidth = menuTitleAnimArea.outerWidth() + pagePadding;
		lengthOfAnimation = -((menuTitleAnimWidth - outletWidth));
		animationTiming = Math.ceil((menuTitleAnimWidth - outletWidth) / 100 * 5) <= 2 ? 2 : Math.ceil((menuTitleAnimWidth - outletWidth) / 100 * 5); // min 2 sec.
	}

	function animateFrom() {
		TweenMax.to(menuTitleAnimArea, animationTiming, {x: lengthOfAnimation, delay: delay, ease: Power1.easeOut, onComplete: animateBack});
	}

	function animateBack() {
		TweenMax.to(menuTitleAnimArea, animationTiming, {x: pagePadding, delay: delay/2, ease: Power1.easeOut, onComplete: animateFrom});
	}

	function resetMarquee() {
		TweenMax.killTweensOf(menuTitleAnimArea);
		TweenMax.set(menuTitleAnimArea, {clearProps: "all", delay: delay/2});
	}


	function TestMenuForMarquee() {
	    if (menuTitleAnimWidth >= outletWidth) {
			menuTitleAnimArea.addClass('animation');
			animateFrom();
		} else {
			menuTitleAnimArea.addClass('no-animation');
		}
	}

	$(document).ready(function () {
		init();
	});
}();