var Tivoli = Tivoli || {};

// Like jQuery serialize array, but checkboxes are included even when not checked, and instead of using the checkbox values on/off use true/false
$.fn.customSerializeArray = function (options) {
    var settings = {
        on: 'true',
        off: 'false'
    };
    if (options) {
        settings = $.extend(settings, options);
    }
    
    function getCheckBoxes(containerOrSelf) {
        return containerOrSelf.find("input[type='checkbox']").andSelf().filter("input[type='checkbox']");
    }

    getCheckBoxes($(this)).each(function () {
        $(this).attr('value', this.checked ? settings.on : settings.off).attr('checked', true);
    });

    var s = ($(this).serializeArray());
    
    getCheckBoxes($(this)).each(function() {
        var $this = $(this);
        $this.attr('checked', $this.val() == settings.on ? true : false);
    });
    return s;
};

Tivoli.validation = (function () {

    // Key = input[type=file]'s id
    var fileData = {};
    var validators = {};

    function setupValidation(forms) {
        forms.on("setup-validation", function() {
            var validationForm = $(this);

            var prefix = validationForm.data("id-prefix");
            if (!prefix) {
                prefix = "";
            }

            var button = validationForm.find(".js-custom-submit a");
            if (button.length > 0) {
                button.on("click", function (e) {
                    e.preventDefault();
                    if (validationForm.valid()) {
                        validationForm.submit();
                    }
                });
            }

            var currentItemPath = Tivoli.getCurrentItemPath();

            var validator = validate(validationForm, prefix, currentItemPath);
            validators[prefix] = validator;

            addMultiCheckboxRule();
            addEanNumberRule();
            addValidDate3DdlRule();
            addRegExRule("regex");
            addRegExRule("customRegex1");
            addRegExRule("customRegex2");
            addRegExRule("customRegex3");

            $.each(validationForm.find(".js-validation-input"), function () {
                calcRules($(this), prefix, validator, validationForm);
            });

            // Make it possible to trigger a form save without validation
            validationForm.on("form-save", function() {
                var action = validationForm.attr("action");
                var type = validationForm.attr("method");
                if (!action || !type) {
                    return;
                }

                var request = createFormRequest(validationForm, currentItemPath);
                
                $.ajax({
                    url: action,
                    traditional: true,
                    data: JSON.stringify(request),
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    error: function (err) {
                    },
                    success: function (data) {
                    },
                    type: type
                });
            });
        });
    }
    
    function createFormRequest(form, currentItemPath) {
        var request = {};
        var serializedForm = nameValueToObject($(form).find(".js-validation-input").not(".ignore").customSerializeArray());
        modifyFormCollapsingDatePicker3Ddls(form, serializedForm);
        modifyFormAddFileData(form, serializedForm);

        request.Form = serializedForm;
        request.CurrentItemUrl = currentItemPath;

        var formRenderingId = form.find(".js-form-rendering-id").val();
        if (formRenderingId) {
            request.FormRenderingId = formRenderingId;
        }
        return request;
    }
    
    function validate(validationForm, prefix, currentItemPath) {
        var validator = validationForm.validate({
            submitHandler: function (form) {
                if (!onForceValidation(validationForm)) {
                    // First time forced - retrigger validation
                    return;
                }

                var action = validationForm.attr("action");
                var type = validationForm.attr("method");
                if (!action || !type) {
                    return;
                }

                var request = createFormRequest(validationForm, currentItemPath);

                if ($(form).data("custom-post")) {
                    $(form).trigger("custom-post");
                } else {
                    $(validationForm).trigger("form-request", [request]);

                    var timeout = $(form).data("timeout");
                    if (!timeout) {
                        timeout = 60000;
                    }

                    $.ajax({
                        url: action,
                        traditional: true,
                        timeout: timeout,
                        data: JSON.stringify(request),
                        dataType: "json",
                        contentType: "application/json; charset=utf-8",
                        error: function (err) {
                            $(validationForm).trigger("form-response", [err]);
                            $(validationForm).trigger("form-error", [err]);
                        },
                        success: function (data) {
                            // Show errors if any
                            var anyErrors = false;
                            for (var errorKey in data.FormErrors) {
                                if (data.FormErrors.hasOwnProperty(errorKey)) {
                                    anyErrors = true;
                                }
                            }
                            modifyFormUncollapseDatePicker3Ddls(validationForm, data.FormErrors);

                            $(validationForm).trigger("form-response", [data]);
                            if (anyErrors) {
                                $.when($(validationForm).trigger("form-error", [data])).done(function () {
                                    validator.showErrors(data.FormErrors);
                                });
                                $(validationForm).trigger("form-error", [data]);
                            } else {
                                $(validationForm).trigger("form-success", [data]);
                            }
                        },
                        type: type
                    });
                }
            },
            showErrors: function (errorMap, errorList) {
                for (var j = errorList.length - 1; j >= 0; j--) {
                    var errorObj = errorList[j];
                    if (typeof errorObj.element === "undefined") {
                        errorList.splice(j, 1);
                    }
                }

                this.defaultShowErrors();
                for (var i = errorList.length - 1; i >= 0; i--) {
                    var error = errorList[i];
                    var $elem = $(error.element);

                    // Hide error label when error text is empty
                    if (error.message.length < 1) {
                        $elem.parent().find("label.error").hide();
                    }

                    showFirstDatePicker3DdlError($elem);
                }
            },
            errorPlacement: function (error, element) {
                error.addClass("error-text");
                element.parents(".js-input-container").append(error);
            },
            ignore: ".ignore, .not-required, :hidden:not(.js-validation-input), .js-form-rendering-id",
            focusInvalid: false,
            invalidHandler: function (form, theValidator) {
                if (!onForceValidation(validationForm)) {
                    // First time forced - retrigger validation
                    return;
                }

                if (!theValidator.numberOfInvalids())
                    return;

                // Default = 60
                var headerHeight = 60;
                var header = $("#head-section");
                if (header.length > 0) {
                    headerHeight = header.outerHeight();
                }

                var errorElem = $(validator.errorList[0].element);
                var selectWrapper = errorElem.parents(".selectric-js-validation-input");
                if (selectWrapper.length > 0) {
                    // Error input is a selectric wrapped drop down list. Use wrapper
                    errorElem = selectWrapper;
                }

                var pos = errorElem.offset().top;
                pos = Math.max(0, pos - headerHeight);
                
                if(!$(validationForm).attr('data-disable-auto-scroll')) {
                    $('html, body').animate({
                        scrollTop: pos
                    }, 100);
                }

                errorElem.focus();
                $(validationForm).trigger('form-failed');
            }
        });
        return validator;
    }
    
    function onForceValidation(validationForm) {
        // First time retrigger validation, so custom validation methods knows they should show error messages
        var validated = validationForm.data("validated");
        validationForm.data("validated", true);
        if (!validated) {
            setTimeout(function() {
                if (validationForm.valid()) {
                    validationForm.submit();
                }
            }, 1);
        }

        return validated;
    }

    function getValidator($form) {
        return validators[$form.data("id-prefix")];
    }
    
    function calcRules($input, formIdPrefix, validator, validationForm) {
        $input.rules("remove");

        if (!validator) {
            validator = getValidator(validationForm);
        }

        var rules = {
            messages: {}
        };

        var isRequired = $input.data("required");
        var requiredMessage = $input.data("required-error");

        rules.required = isRequired;
        if (!requiredMessage || requiredMessage.trim().length < 1) {
            requiredMessage = "";
        }
        rules.messages.required = requiredMessage;

        var dependsOn = $input.data('depends-on');
        if(dependsOn){
            rules.required = function() {
                    return $("#" + formIdPrefix + dependsOn +":checked").length > 0;
            }
        }

        var error = $input.data("error");
        if (!error) {
            error = "";
        }

        var sameAs = $input.data("same-as");
        if (sameAs) {
            rules.equalTo = "#" + formIdPrefix + sameAs;
            var sameAsError = $input.data("same-as-error");
            if (sameAsError) {
                rules.messages.equalTo = sameAsError;
            }
        }

        var isEmail = $input.data("is-email");
        if (isEmail) {
            rules.email = true;
            rules.messages.email = error;
        }
        
        var isEanNumber = $input.data("is-eannumber");
        if (isEanNumber) {
            rules.eannumber = true;
            rules.messages.eannumber = error;
        }

        var isMultiCheckBox = $input.data("is-multicheckbox");
        if(isMultiCheckBox) {
            rules.multiCheckbox = true;
            rules.messages.multiCheckbox = "";
            $input.change(function(){
                $input.valid();
            });
        }

        var maxLength = $input.data("max-length");
        if (maxLength) {
            rules.maxlength = maxLength;
            var maxLengthError = $input.data("max-length-error");
            if (maxLengthError) {
                rules.messages.maxlength = maxLengthError;
            }
        }
        
        var minLength = $input.data("min-length");
        if (minLength) {
            rules.minlength = minLength;
            var minLengthError = $input.data("min-length-error");
            if (minLengthError) {
                rules.messages.minlength = minLengthError;
            }
        }

        //Three custom regex
        var customContains = $input.data("custom-validation")
        if(customContains)
        {
            rules.customRegex1 = customContains;
            rules.messages.customRegex1 = $input.data("custom-validation-error")
        }
        
        var customContains3 = $input.data("custom-validation2");
        if(customContains3)
        {
            rules.customRegex2 = customContains3;
            rules.messages.customRegex2 = $input.data("custom-validation2-error")
        }
        
        var customContains3 = $input.data("custom-validation3");
        if(customContains3)
        {
            rules.customRegex3 = customContains3;
            rules.messages.customRegex3 = $input.data("custom-validation3-error")
        }
        

        // Handling of date picker in 3 drop down lists
        var regexStr = $input.data("regex");
        if (regexStr) {
            rules.regex = regexStr;
            rules.messages.regex = $input.data("regex-error");
        }

        // Handling of date picker in 3 drop down lists
        if ($input.data("is-date-threeddl")) {
            $input.on("change", function () {
                showFirstDatePicker3DdlError($(this));
            });
            rules.date3ddl = true;
            rules.messages.date3ddl = error;
        }

        if ($input.attr("type") === "file") {
            if ($input.data("fallback-enabled")) {
                window.mOxie.Env.swf_url = "/js/tivoli-responsive/vendor/ie9/Moxie.swf";
                window.FileReader = mOxie.FileReader;
                var ip = new mOxie.FileInput({
                    browse_button: "fallback" + $input.attr("id")
                });
                ip.onchange = function (e) {
                    var file = e.target.files[0];

                    handleFileChange($input, file, function(reader, ev) {
                        return ev.target.result;
                    }, error, validator);
                };
                ip.init();
            } else {
                $input.on("change", function () {
                    var file = $input.get(0).files[0];

                    handleFileChange($input, file, function (reader, ev) {
                        return reader.result;
                    }, error, validator);
                });
            }
        }

        validationForm.trigger("add-custom-rules", {
            rules: rules,
            input: $input
        });

        $input.rules("add", rules);
    }

    function nameValueToObject(nameValueArray) {
        var obj = {};
        for (var i = 0; i < nameValueArray.length; i++) {
            var nv = nameValueArray[i];
            obj[nv.name] = nv.value;
        }
        return obj;
    }

    function handleMultiCheckBoxErrorMessage(success) {
        var errorMsg = $(".js-multicheckbox-error");
        if(success) {
            errorMsg.hide();
            $('[data-is-multicheckbox="true"]').removeClass('error');
        } else {
            errorMsg.css('display', 'inline-block');
            $('[data-is-multicheckbox="true"]').addClass('error');
        }

    }

    /*********************** FILE INPUT ****************************************************/
    function modifyFormAddFileData($form, form) {
        var fileInputs = $form.find(".js-validation-input[type=file]");
        $.each(fileInputs, function() {
            var id = $(this).attr("id");
            var name = $(this).attr("name");
            if (fileData[id]) {
                form[name] = fileData[id];
            }
        });
    }

    

    function handleFileChange($input, file, getResult, sizeError, validator) {
        if (!file) {
            return;
        }

        var legalFileExtension = true;
        var accept = $input.attr("accept");
        if (accept) {
            var extension = file.name.split('.').pop().toLowerCase();
            accept = accept.replace(/\./g, "").toLowerCase();
            var types = accept.split(",");
            legalFileExtension = types.indexOf(extension) > -1;
        }

        if (legalFileExtension) {
            if (!$input.data("max-file-size") || parseInt($input.data("max-file-size"), 10) >= Math.round(file.size)) {
                var reader = new FileReader();
                reader.onloadend = function (e) {
                    var result = getResult(reader, e);
                    $input.trigger("file-data-changed", [result, file]);
                    fileData[$input.attr("id")] = result;
                };
                reader.readAsDataURL(file);
                hideErrorMessage($input);
            } else {
                // File too large
                var sizeErr = {};
                sizeErr[$input.attr("name")] = sizeError;
                validator.showErrors(sizeErr);
            }
        } else {
            // Wrong type
            var wrongFileTypeError = $input.data("file-type-error");
            if (!wrongFileTypeError) {
                wrongFileTypeError = "";
            }
            var err = {};
            err[$input.attr("name")] = wrongFileTypeError;
            validator.showErrors(err);
        }
    }
    
    function hideErrorMessage($input) {
        $input.parents(".js-input-container").find("label.error").hide();
    }

    /*********************** DATE PICKER 3DDL***********************************************/
    function modifyFormCollapsingDatePicker3Ddls($form, form) {
        $.each($form.find(".js-date-3ddl"), function() {
            var $elem = $(this);
            var id = $elem.data("id");
            var month = form[$elem.data("id-month")];
            var day = form[$elem.data("id-day")];
            var year = form[$elem.data("id-year")];

            delete form[$elem.data("id-month")];
            delete form[$elem.data("id-day")];
            delete form[$elem.data("id-year")];

            if (month && day && year) {
                var date = getDate(month, day, year);
                if (date) {
                    form[id] = Tivoli.dateUtil.formatLocalDate(date);
                }
            }
        });
    }
    
    function modifyFormUncollapseDatePicker3Ddls($form, form) {
        $.each($form.find(".js-date-3ddl"), function () {
            var $elem = $(this);
            var id = $elem.data("id");
            var error = form[id];

            if (error) {
                delete form[id];

                form[$elem.data("id-month")] = error;
            }
        });
    }

    function showFirstDatePicker3DdlError($elem) {
        var date3DdlContainer = $elem.parents(".js-date-3ddl");
        if (date3DdlContainer.length > 0) {
            var labels = date3DdlContainer.find("label.error");
            
            // Only show first error in date 3ddl
            var firstAlreadyFound = false;
            for (var j = 0; j < labels.length; j++) {
                var label = $(labels.get(j));
                
                if ($("#" + label.attr("for")).hasClass("valid")) {
                    continue;
                }

                if (!firstAlreadyFound) {
                    label.show();
                    firstAlreadyFound = true;
                } else {
                    label.hide();
                }
            }
        }
    }

    function addValidDate3DdlRule() {
        jQuery.validator.addMethod("date3ddl", function (value, element) {
            var form = $(element).parents("form");
            if (form.length > 0 && !form.data("validated")) {
                // Do not show values before being validated
                return true;
            }

            var $elem = $(element);
            var container = $elem.parents(".js-date-3ddl");
            var month = container.find(".js-date-month").val();
            var day = container.find(".js-date-day").val();
            var year = container.find(".js-date-year").val();
            if ($elem.data("required")) {
                if (!month || !day || !year) {
                    // Required error takes precedence
                    return true;
                }
            } else {
                if (!month && !day && !year) {
                    // If not required and no input, do not check for format valid
                    return true;
                }
            }
            return getDate(month, day, year) != null;
        });
    }
    
    function getDate(month, day, year) {
        var date = new Date(year, month - 1, day, 0, 0, 0, 0);
        if (Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date.getTime())) {
            // Make sure date is same
            if (date.getFullYear() == year && (date.getMonth() + 1) == month && date.getDate() == day) {
                return date;
            }
        }

        return null;
    }
    /**************************************************************************/
    
    function addEanNumberRule() {
        jQuery.validator.addMethod("eannumber", function (value, element) {
            var v = value;
            if (v.length < 1) {
                return true;
            }
            var values = v.split(' ');
            if (values.length != 1) {
                return false;
            }
            var valueAsInteger = parseInt(values[0]);
            if (valueAsInteger == "NaN") {
                return false;
            }

            if (v.length != 13) {
                return false;
            }

            var cs = 0;
            for (var i = 0; i < 12; i++) {
                var digit = v.charAt(i);
                if (digit < '0' || digit > '9') {
                    return false;
                }

                digit = parseInt(digit);

                if ((i % 2) != 0)
                    cs = cs + digit * 3;
                else
                    cs = cs + digit * 1;
            }

            cs = (10 - (cs % 10)) % 10;
            return v.charAt(12) == cs;
        });
    }

     function addMultiCheckboxRule() {
        jQuery.validator.addMethod("multiCheckbox", function (value, element) {
            if($(element).parents('.js-ignore-validation').length > 0) {
                return true;
            }

            var checked = $('[data-is-multicheckbox="true"]:checked').length != 0;
            handleMultiCheckBoxErrorMessage(checked);
            return checked;
        });
    }

    function addRegExRule(ruleName) {
        jQuery.validator.addMethod(
            ruleName,
            function (value, element, regexp) {
                var re = new RegExp(regexp, "i");
                return this.optional(element) || re.test(value, "g");
            },
            "Wrong input format"
        );
    }
    
    function forceInit(forms) {
        if(!forms) {
            forms = $(".js-validation"); 
        }
        
        setupValidation(forms);
        setTimeout(function () {
            forms.trigger("pre-setup-validation");
            forms.trigger("setup-validation");
        }, 1);
    }

    $(document).ready(function() {
        forceInit();
    });

    return {
        calcRules: calcRules,
        getValidator: getValidator,
        validate: validate,
        createFormRequest: createFormRequest,
        forceInit: forceInit
    };
})();