"use strict";

var Tivoli = Tivoli || {};

Tivoli.paymentReceiver = (function () {

    var url = "/webshop/checkout/endpayment";
    var timer;

    function getstatus() {
        var c = $(".payment-receiver-section");
        if (c.length === 0) {
            return;
        }

        $.ajax({
            type: "POST",
            url: url + "?" + c.attr("data-params"),
            data: {},
            timeout: 300000, //5 min
            success: function (response) {
                if (response != 'inprogress' && response != '') {
                    clearInterval(timer);
                    window.location.href = response;
                } else {
                    timer = setTimeout(getstatus, 1000);
                }
            },
            error: function(err) {
                console.log("Err", err);
            },
            dataType: "json"
        });
    };

    function setupPaymentReceiver() {
        timer = setTimeout(getstatus, 1000);
    }

    $(document).ready(function () {
        setupPaymentReceiver();
    });
})();