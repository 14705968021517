var Tivoli = Tivoli || {};

Tivoli.parallaxtop = function () {


	var $html =				$('html'),
		$wrapper =			"",
		$content =			"",
		$window =			$(window),
		menuHeight = 		150,
		windowHeight =		0,
		windowPosition =	0,
		scrollTop = 		0,
		prevScrollTop;

    var smallBreak = Tivoli.settings.width.small,
          littleMediumBreak = Tivoli.settings.width.littlemedium,
          mediumBreak = Tivoli.settings.width.medium,
          largeBreak = Tivoli.settings.width.large,
          windowWidth,
          MQWidthStatus;

	

	function init() {

	    $wrapper = $('.js-parallax'),
	        $content = $wrapper.find('.hero-media'),
	        $window = $(window);


		// NO PARALLAX FOR BROWSERS NOT SUPPORTING ANIMATION FRAME (performance issue) or touch devices (looks bad)
		if($wrapper.length === 0 || !Modernizr.prefixed('requestAnimationFrame', window) || Tivoli.vars.isAnyTouchDevice) {
			return;
		}

		updateMQWidth();
		$(window).smartresize(function () {
		    updateMQWidth();
		    animateElements();
		});

        //NO PARALLAX FOR MOBILE
		if (MQWidthStatus == 'small') {
		    return;
		}

		setupValues();

		if (!Tivoli.vars.isAnyTouchDevice) {
			animateElements();
	    }
	  

		$window.on('scroll', function() {
		    if (MQWidthStatus != 'small' && $wrapper.length !== 0 && Modernizr.prefixed('requestAnimationFrame', window) && !$html.hasClass('IOS7')) {
	            setupValues();
	            updatePage();
	        }
	    });
	}

	function setupValues() {
		scrollTop = $window.scrollTop();
		windowHeight = $(window).height();
		windowPosition = $wrapper.offset().top;
	}

	function updatePage() {

		//if(Modernizr.prefixed('requestAnimationFrame', window)) {
			window.requestAnimationFrame(function () {
				setAllElements();
			});
		/*} else {
			setAllElements();
		}*/
	}
	function setAllElements() {
		if(setScrollTops() !== prevScrollTop) {
			setScrollTops();
			animateElements();

			prevScrollTop = $window.scrollTop();
		}
	}

	function setScrollTops() {
		scrollTop = $window.scrollTop();
		return scrollTop;
	}

    function animateElements() {
        $wrapper.each(function () {
            if (MQWidthStatus == 'small') {
                $(this).css({
                    position: 'relative',
                    transform: 'none'
                });
            } else {
                var newscrollTop = $(window).scrollTop(),
                       elementOffset = $(this).offset().top,
                       distance = (elementOffset - newscrollTop) - menuHeight;
                
                if (distance < windowHeight) {

                    if ($(this).hasClass("js-parallax--right")) {
                        $(this).css({
                            position: 'absolute',
                            transform: 'translateX(' + ((windowHeight - distance) / 10).toFixed(2) + 'px)'
                        });
                
                    } else {
                        $(this).css({
                            position: 'absolute',
                            transform: 'translateX(' + ((distance - windowHeight) / 10).toFixed(2) + 'px)'
                        });


                    }

                }
            }
        });
    }

    function updateMQWidth() {
        windowWidth = $(window).width();
        MQWidth();
    }

    function MQWidth() {
        if ((largeBreak < windowWidth && windowWidth > mediumBreak) && MQWidthStatus !== 'xlarge') {
            MQWidthStatus = 'xlarge';
            $(document).trigger('breakpoint');
        } else if ((largeBreak > windowWidth && windowWidth > mediumBreak) && MQWidthStatus !== 'large') {
            MQWidthStatus = 'large';
            $(document).trigger('breakpoint');
        } else if ((littleMediumBreak < windowWidth && mediumBreak > windowWidth) && MQWidthStatus !== 'medium') {
            MQWidthStatus = 'medium';
            $(document).trigger('breakpoint');
        } else if (smallBreak < windowWidth && littleMediumBreak > windowWidth && MQWidthStatus !== 'littlemedium') {
            MQWidthStatus = 'littlemedium';
            $(document).trigger('breakpoint');
        } else if ((smallBreak > windowWidth) && MQWidthStatus !== 'small') {
            MQWidthStatus = 'small';
            $(document).trigger('breakpoint');
        }
    }

    $(document).ready(function () {
        init();
    });

    $(document).on("jsonLoaded", function () {
        init();
    });
}();