Tivoli.ButtonMessage = (function () {

    function showErrorMessageDefault(button, message) {
        showErrorMessage(button, message, 7000);
    }

    function showMessageDefault(button, message) {
        showMessage(button, message, 7000);
    }

    function showErrorMessage(button, message, milliSecondsShown) {
        addMessage(button, message, true);

        if (milliSecondsShown) {
            setTimeout(function () {
                removeMessage(button);
            }, milliSecondsShown);
        }
    }

    function showMessage(button, message, milliSecondsShown) {
        addMessage(button, message, false);

        if (milliSecondsShown) {
            setTimeout(function () {
                removeMessage(button);
            }, milliSecondsShown);
        }
    }

    function addMessage(button, message, isError) {
        var errorClass = isError ? "button__message--error" : "";

        if (isError && typeof message !== "string") {
            message = Tivoli.getGeneralErrorMessage();
        }

        button.wrap('<div class="button__information-box"></div>');
        button.after('<div class="button__message ' + errorClass + '">' + message + '</div>');
        button.data("messageshown", true);
    }

    function removeMessage(button) {
        if (button.data("messageshown")) {
            button.unwrap();
            button.parents().find('.button__message').remove();
            button.data("messageshown", false);
        }
    }

    return {
        showMessage: showMessage,
        showMessageDefault: showMessageDefault,
        showErrorMessage: showErrorMessage,
        showErrorMessageDefault: showErrorMessageDefault,
        removeMessage: removeMessage
    };
})();

Tivoli.CircleLoader = function () {

    var svgCircleRadius = 8;
    var svgCircleThichness = 1;
    var fullCircleAnimationTime = 1000;

    var loaderStates = {};

    function init() {
    }

    function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        angleInDegrees = angleInDegrees - 90;
        var angleInRadians = angleInDegrees * Math.PI / 180.0;
        var x = centerX + radius * Math.cos(angleInRadians);
        var y = centerY + radius * Math.sin(angleInRadians);
        return [x, y];
    }

    function loadInitial($circleLoader, callback) {
        show($circleLoader);
        loadToPercent($circleLoader, 60, callback, 5000);
        hideButtonTextToggle($circleLoader, true);
    }

    function loadFinish($circleLoader, callback) {
        loadToPercent($circleLoader, 100, callback, -1);
    }

    function reset($circleLoader) {
        var id = $circleLoader.data("id");

        loadToPercent($circleLoader, 0, function () {
            hide($circleLoader);
            if (loaderStates[id]) {
                loaderStates[id].percent = 0;
            }
        }, 0);
        hideButtonTextToggle($circleLoader, false);
    }

    function hideButtonTextToggle($circleLoader, hideText) {
        var hideClass = $('.js-hide-text-after');
        if ($circleLoader.is(hideClass)) {
            if (hideText) {
                $circleLoader.addClass('circle-loader--hide-text');
            } else {
                $circleLoader.removeClass('circle-loader--hide-text');
            }
        };
    }

    function show($circleLoader) {
        $circleLoader.show();
    }

    function hide($circleLoader) {
        $circleLoader.hide();
    }

    function loadToPercent($circleLoader, toPercent, callback, animationTime) {
        var id = $circleLoader.data("id");

        var pathRight = $("#loader-ring-right-" + id);
        var pathLeft = $("#loader-ring-left-" + id);

        // Init state
        if (!loaderStates[id]) {
            loaderStates[id] = { percent: 0 };
        }

        // Stop animation if running
        if (loaderStates[id].animation) {
            loaderStates[id].animation.clearQueue();
            loaderStates[id].animation.stop();
        }

        if (animationTime == -1) {
            animationTime = Math.abs(loaderStates[id].percent - toPercent) / 100 * fullCircleAnimationTime;
            if (loaderStates[id].percent < 50) {
                // Make long animations a bit shorter
                animationTime = animationTime * 0.75;
            }
        }

        loaderStates[id].animation = $({ deg: loaderStates[id].percent }).animate({ deg: toPercent }, {
            duration: animationTime,
            step: function (now) {
                loaderStates[id].percent = now;
                paintLoadToPercent(pathRight, pathLeft, now);
            },
            easing: 'easeOutQuint',
            complete: function () {
                loaderStates[id].percent = toPercent;
                if (typeof (callback) == "function") {
                    callback();
                }
            }
        });
    }

    function paintLoadToPercent(pathRight, pathLeft, toPercent) {
        if (toPercent > 50) {
            pathRight.attr("d", getCircleArea(50, true));
            pathLeft.attr("d", getCircleArea(toPercent - 50, false));
        } else {
            pathRight.attr("d", getCircleArea(toPercent, true));
            pathLeft.attr("d", getCircleArea(0, false));
        }
    }

    function getCircleArea(toPercent, isRight) {
        var innerRadius = svgCircleRadius - svgCircleThichness;

        var fromDegree;
        var toDegree;
        if (isRight) {
            fromDegree = 0;
            toDegree = 360 * toPercent / 100;
        } else {
            fromDegree = 180;
            toDegree = 360 * toPercent / 100 + 180;
        }

        var a1 = polarToCartesian(svgCircleRadius, svgCircleRadius, svgCircleRadius, fromDegree);
        var a2 = polarToCartesian(svgCircleRadius, svgCircleRadius, svgCircleRadius, toDegree);
        var a3 = polarToCartesian(svgCircleRadius, svgCircleRadius, innerRadius, toDegree);
        var a4 = polarToCartesian(svgCircleRadius, svgCircleRadius, innerRadius, fromDegree);

        var startPos = "M" + a1[0] + "," + a1[1];
        var outerArc = "A" + (svgCircleRadius) + "," + (svgCircleRadius) + ",0,0," + (isRight ? 1 : 1) + "," + a2[0] + "," + a2[1];
        var lineToInner = "L" + a3[0] + "," + a3[1];
        var arcCrossToInner = "A" + (innerRadius) + "," + (innerRadius) + ",0,0," + (isRight ? 0 : 0) + "," + a4[0] + "," + a4[1];

        return startPos + " " + outerArc + " " + lineToInner + " " + arcCrossToInner;
    }

    $(document).ready(function () {
        init();
    });

    return {
        loadInitial: loadInitial,
        loadFinish: loadFinish,
        reset: reset
    };
}();