var Tivoli = Tivoli || {};

Tivoli.CookieDisclaimer = (function () {

    var init = function () {

        var vars = {
            cookie: Tivoli.Cookies
        };

        var cookieDisclaimer = document.querySelector('.cookie-disclaimer'),
            $restrictionModal = $('.restriction-modal'),
            cookieHeight = $(cookieDisclaimer).height(),
            headerHeight = 60,
            calcHeight = cookieHeight + headerHeight,
            cookieSeen = false;

        var disclaimer = vars.cookie.get("cookieDisclaimer");
        var ageRestrictionApproval = vars.cookie.get("ageRestrictionApproval"); // Don't show, if age-restriction is present (this will set cookie-disclaimer).

        if (disclaimer != "true") {
            if (ageRestrictionApproval != "true" && $restrictionModal.length > 0) {
                return;
            }
            openCookieDisclaimer();
        }

        function openCookieDisclaimer() {
            $('.cookie-disclaimer').addClass('cookie-disclaimer--enabled');

            var $cookieDisclaimerBtn = $('.cookie-disclaimer__close');
            var activated;

            $cookieDisclaimerBtn.on('click touchstart', function (e) {
                e.preventDefault();
                e.stopPropagation();
                disableCookieDisclaimer();
            });

            window.addEventListener('scroll', stickyScroll, false);
        }

        function disableCookieDisclaimer() {
            $('.cookie-disclaimer').removeClass('cookie-disclaimer--enabled');
            vars.cookie.set("cookieDisclaimer", "true", 500);
            window.removeEventListener('scroll', stickyScroll, false);
        }

        function stickyScroll(e) {
            if (window.pageYOffset > (calcHeight) && cookieSeen) {
                disableCookieDisclaimer();
            }
            if(window.pageYOffset < (calcHeight)){
                cookieSeen = true;
            }
        }  
    };

    $(document).ready(function () {
        init();
    });
})();