var Tivoli = Tivoli || {};
Tivoli.Shop = Tivoli.Shop || {};

Tivoli.Shop.delivery = (function () {
    var container;
    var formIdPrefix = "";

    function init() {
        container = $(".js-delivery");

        if (container.length === 0) {
            return;
        }

        formIdPrefix = container.data("id-prefix");
        if (!formIdPrefix) {
            formIdPrefix = "";
        }


        handleAlternativeBillingAddress();
        handleBillingAddress();
        handlePayment();
        handleFormPost();
        handleDeliveryLinkBack();
        preventCopyPaste();
        handleMitTivoliEditClick();
        handleApiPrefetch();
        
    }
    
    function initLoad() {
        if (container == null || container.length === 0) {
            return;
        }
        handleEanRequired();
        handleUserId();
    }
    
    function handleAlternativeBillingAddress() {
        var deliveryCheckbox = $("#" + formIdPrefix + "AlternativeBillingAddress");
        var deliveryAddressContainer = container.find(".js-delivery-billing-address-container");
        if (!deliveryCheckbox || !deliveryAddressContainer) {
            return;
        }
        deliveryCheckbox.on("change", function() {
            if (deliveryCheckbox.is(":checked")) {
                setVisibilityForDeliveryInfo(true, deliveryAddressContainer);
            } else {
                setVisibilityForDeliveryInfo(false, deliveryAddressContainer);
            }
        });
        var selectedValue = deliveryCheckbox.is(":checked");
        setVisibilityForDeliveryInfo(selectedValue, deliveryAddressContainer);
    }

    function handleBillingAddress() {
        var deliveryCheckbox = $("#" + formIdPrefix + "UseBillingAddress");
        var deliveryAddressContainer = container.find(".js-delivery-billing-address-container");
        if (!deliveryCheckbox || !deliveryAddressContainer) {
            return;
        }
        deliveryCheckbox.on("change", function() {
            if (deliveryCheckbox.is(":checked")) {
                setVisibilityForDeliveryInfo(true, deliveryAddressContainer);
            } else {
                setVisibilityForDeliveryInfo(false, deliveryAddressContainer);
            }
        });
        var selectedValue = deliveryCheckbox.is(":checked");
        setVisibilityForDeliveryInfo(selectedValue, deliveryAddressContainer);
    }
    
    function setVisibilityForDeliveryInfo(show, deliveryAddressContainer) {
        if (show) {
            deliveryAddressContainer.show();
            deliveryAddressContainer.find(".js-validation-input").removeClass("ignore");
        } else {
            deliveryAddressContainer.hide();
            deliveryAddressContainer.find(".js-validation-input").addClass("ignore");
        }
    }
    
    function handlePayment() {
        $(".js-payment-method input[type=radio]").change(function() {
            setPaymentVisibility(this.value);
        });

        var selectedValue = $(".js-payment-method input[type=radio]:checked").val();
        setPaymentVisibility(selectedValue);

        // Handle show save credit card
        var saveCreditCardInput = $(".js-payment-choose-creditcard select");
        if (saveCreditCardInput.length > 0) {
            saveCreditCardInput.change(function () {
                toggleSaveCreditCardContainer(this.value === "0");
            });

            var saveCreditCardSelectedValue = saveCreditCardInput.val();
            toggleSaveCreditCardContainer(saveCreditCardSelectedValue === "0");
        }
    }

    function setPaymentVisibility(value) {
        toggleCreditCardContainer(value === "CreditCard");
        toggleInvoiceContainer(value === "Invoice");
        toggleMobilePayContainer(value === "MobilePay");
    }
    
    function toggleSaveCreditCardContainer(show) {
        var saveCreditCardContainer = $(".js-payment-save-creditcard");

        saveCreditCardContainer.find(".js-input-container").addClass("label-overflow"); // Is not added normally if container has display: none to begin with

        if (show) {
            saveCreditCardContainer.show();
            saveCreditCardContainer.find(".js-validation-input").removeClass("ignore");
        } else {
            saveCreditCardContainer.hide();
            saveCreditCardContainer.find(".js-validation-input").addClass("ignore");
        }
    }

    function toggleCreditCardContainer(show) {
        var creditCardContainer = $(".js-payment-creditcards");

        if (show) {
            creditCardContainer.show();
            creditCardContainer.find(".js-validation-input").removeClass("ignore");
        } else {
            creditCardContainer.hide();
            creditCardContainer.find(".js-validation-input").addClass("ignore");
        }
    }

    function toggleInvoiceContainer(show) {
        var invoiceContainer = $(".js-payment-invoice");

        if (show) {
            invoiceContainer.show();
            invoiceContainer.find(".js-validation-input").removeClass("ignore");
            showHideUserId();
        } else {
            invoiceContainer.hide();
            invoiceContainer.find(".js-validation-input").addClass("ignore");
        }
    }

    function toggleMobilePayContainer(show) {
        var mobilePayContainer = $(".js-payment-mobilepay");

        if (show) {
            mobilePayContainer.show();
            mobilePayContainer.find(".js-validation-input").removeClass("ignore");
        } else {
            mobilePayContainer.hide();
            mobilePayContainer.find(".js-validation-input").addClass("ignore");
        }
    }

    function handleEanRequired() {
        var form = $(".js-delivery");
        var eanNumber = form.find(".js-delivery-eannumber .js-validation-input");
        if (eanNumber.length === 0) {
            return;
        }

        var prefix = form.data("id-prefix");
        if (!prefix) {
            prefix = "";
        }

        var eanIsrequired = false;

        form.find(".js-delivery-companytype input[type=radio]").change(function() {
            eanIsrequired = $(this).hasClass("required");
            setEanNumberRequired(eanNumber, this.value, prefix, eanIsrequired, form);
        });
        var selectedValue = form.find(".js-delivery-companytype input[type=radio]:checked").val();
        setEanNumberRequired(eanNumber, selectedValue, prefix, eanIsrequired, form);
    }
    
    function setEanNumberRequired(eanNumber, value, formPrefixId, eanisRequired, form) {
        var isRequired = value === "Public" || eanisRequired;
        eanNumber.data("required", isRequired ? true : false);

        togglePlaceholderRequired(eanNumber, isRequired);

        Tivoli.validation.calcRules(eanNumber, formPrefixId, null, form);
    }

    function showHideUserId() {
        var form = $(".js-delivery");
        var userId = form.find(".js-delivery-user-id");
        var userIdValidation = userId.find(".js-validation-input");
        if (userId.length === 0) {
            return;
        }

        var show = form.find(".js-delivery-companytype input[type=radio]:checked").val() === "Public";
        if (show) {
            userId.show();
            userIdValidation.removeClass("ignore");
        } else {
            userId.hide();
            userIdValidation.addClass("ignore");
        }
    }

    function handleUserId() {
        var form = $(".js-delivery");
        form.find(".js-delivery-companytype input[type=radio]").change(function () {
            showHideUserId();
        });
        
        showHideUserId();
    }
    
    function togglePlaceholderRequired(input, isRequired) {
        var placeholder = input.attr("placeholder");
        var hasRequirement = placeholder.endsWith("*");
        if (isRequired && !hasRequirement) {
            input.attr("placeholder", placeholder + "*");
        }
        if (!isRequired && hasRequirement) {
            input.attr("placeholder", placeholder.substring(0, placeholder.length - 1));
        }
    }

    function handleFormPost() {
        var button = container.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");
        
        container.on("form-request", function () {
            Tivoli.CircleLoader.loadInitial(loader, null);
        });
        
        container.on("form-success", function (e, data) {
            if (data.Success && data.RedirectUrl) {
                Tivoli.CircleLoader.loadFinish(loader, null);
                pushGaCheckoutTrackingEvent();
                window.location.href = data.RedirectUrl;
            } else {
                Tivoli.CircleLoader.reset(loader);
                if(data.RedirectUrl){
                    window.location.href = data.RedirectUrl;
                }else if (data.PaymentError) {
                    $(".shop-notifications span").html(data.PaymentError);
                    $(".shop-notifications").show();
                    window.location.hash = "info";
                } else {
                    // Failed with no error - should not happen
                    $(".shop-notifications span").empty();
                    $(".shop-notifications").hide();
                }
            }
        });
        container.on("form-error", function () {
            Tivoli.CircleLoader.reset(loader);
            
            // Validation error - hide general errors
            $(".shop-notifications span").empty();
            $(".shop-notifications").hide();
        });
    }
    
    function handleDeliveryLinkBack() {
        var button = container.find(".js-delivery-link-back a");
        button.on("click", function(e) {
            var basketType = $(this).data('basket-type')
            // Save data when going back
            container.attr("action", "/WebShop/Checkout/SaveDeliveryInformation?baskettype=" + basketType);
            container.trigger("form-save");
        });
    }

    function preventCopyPaste() {
        var $emailField = container.find("input[type=email]"); //
        $emailField.attr("autocomplete", "off");
        $emailField.on("paste", function (e) {
            e.preventDefault();
        });
    }

     function handleMitTivoliEditClick() {
        $(".js-mit-tivoli-edit").on('click', function(e) {
            e.preventDefault();
            handleMitTivoliEdit();
        });
    }

    function handleMitTivoliEdit() {
        $('.js-is-mit-tivoli .js-checkout-hidden-input').show();
        $('.js-pass-info-affected-cards').show();
        $('.js-mit-tivoli-profile-info').hide();
    }

    function handleApiPrefetch() {
        $('.js-delivery #deliveryformEmail').on('blur', function(e) {
            if($(this).hasClass('valid')) {
                var emailValue = $(this).val();
                $.post( "/MitTivoli/Account/PrefetchAccountByEmail", { email: emailValue } );
            }
        });
    }

    function pushGaCheckoutTrackingEvent() {
        var paymentMethod = $("input[name='PaymentMethod']:checked").val();

        dataLayer.push({
            event: 'checkout2TivoliDk',
            ecommerce: {
                checkout: {
                    actionField: {
                    step: 2, 
                    option: paymentMethod,
                    dimension4: 'Tivoli.dk',
                    } 
               }
            }
        });

    }

    $(document).ready(function() {
        init();
    });

    $(window).load(function() {
        initLoad();
    });

    return {
        
    };
})();