var Tivoli = Tivoli || {};

Tivoli.boxModule = function() {
	"use strict";

	var smallBreak			=	Tivoli.settings.width.small,
		littleMediumBreak	=	Tivoli.settings.width.littlemedium,
		mediumBreak			=	Tivoli.settings.width.medium,
		largeBreak 			=	Tivoli.settings.width.large,
		xLargeBreak     = Tivoli.settings.width.xlarge,
		windowWidth,
		currentMQSize,
		allFulltimeSwipers = [];
	
	function init() {
		if($(".swiper").length === 0) {
		    return;
		}
		callSwiper();
	}


	// SWIPER SETTINGS
	function swiperSettings(swiper) {
		var size = {
			 SixBySixWide: function() {
		        swiper.params.slidesPerView = 6.2;
		        swiper.params.slidesPerGroup = 6;
		        swiper.params.centeredSlides = false;
		        swiper.params.offsetSlidesBefore = 0;
		        swiper.params.offsetSlidesAfter = 0;
		        swiper.params.touchRatio = 0.8;
		        reInitSwiper();
		    },
		    SixBySix: function() {
		        swiper.params.slidesPerView = 6;
		        swiper.params.slidesPerGroup = 6;
		        swiper.params.centeredSlides = false;
		        swiper.params.offsetSlidesBefore = 0;
		        swiper.params.offsetSlidesAfter = 0;
		        swiper.params.touchRatio = 0.8;
		        reInitSwiper();
		    },
		    FiveByFiveWide: function() {
		        swiper.params.slidesPerView = 5.555;
		        swiper.params.slidesPerGroup = 5;
		        swiper.params.centeredSlides = false;
		        swiper.params.offsetSlidesBefore = 0.13;
		        swiper.params.offsetSlidesAfter = 12;
		        swiper.params.touchRatio = 0.8;
		        reInitSwiper();
		    },
		    FiveByFive: function() {
		        swiper.params.slidesPerView = 5;
		        swiper.params.slidesPerGroup = 5;
		        swiper.params.centeredSlides = false;
		        swiper.params.offsetSlidesBefore = 0;
		        swiper.params.offsetSlidesAfter = 0;
		        swiper.params.touchRatio = 0.8;
		        reInitSwiper();
		    },
			FourByFourWide: function() {
				swiper.params.slidesPerView = 4.255;
				swiper.params.slidesPerGroup = 4;
				swiper.params.centeredSlides = false;
				swiper.params.offsetSlidesBefore = 0.13;
				swiper.params.offsetSlidesAfter = 0.12;
				swiper.params.touchRatio = 0.8;
				reInitSwiper();
			},
			FourByFour: function() {
				swiper.params.slidesPerView = 4;
				swiper.params.slidesPerGroup = 4;
				swiper.params.centeredSlides = false;
				swiper.params.offsetSlidesBefore = 0;
				swiper.params.offsetSlidesAfter = 0;
				swiper.params.touchRatio = 0.8;
				reInitSwiper();
			},
			ThreeByThreeWide: function() {
				swiper.params.slidesPerView = 3.2;
				swiper.params.slidesPerGroup = 3;
				swiper.params.centeredSlides = false;
				swiper.params.offsetSlidesBefore = 0.1;
				swiper.params.offsetSlidesAfter = 0.1;
				swiper.params.touchRatio = 0.8;
				reInitSwiper();
			},
			ThreeByThree: function() {
				swiper.params.slidesPerView = 3;
				swiper.params.slidesPerGroup = 3;
				swiper.params.centeredSlides = false;
				swiper.params.offsetSlidesBefore = 0;
				swiper.params.offsetSlidesAfter = 0;
				swiper.params.touchRatio = 0.8;
				reInitSwiper();
			},
			TwoByTwoWide: function() {
				swiper.params.slidesPerView = 2.14;
				swiper.params.slidesPerGroup = 2;
				swiper.params.centeredSlides = false;
				swiper.params.offsetSlidesBefore = 0.07;
				swiper.params.offsetSlidesAfter = 0.07;
				swiper.params.touchRatio = 0.8;
				reInitSwiper();
			},
			TwoByTwoWideNoOffset: function() {
				swiper.params.slidesPerView = 2.14;
				swiper.params.slidesPerGroup = 2;
				swiper.params.centeredSlides = false;
				swiper.params.offsetSlidesBefore = 0;
				swiper.params.offsetSlidesAfter = 1;
				swiper.params.touchRatio = 0.8;
				reInitSwiper();
			},
			TwoByTwo: function() {
				swiper.params.slidesPerView = 2;
				swiper.params.slidesPerGroup = 2;
				swiper.params.centeredSlides = false;
				swiper.params.offsetSlidesBefore = 0;
				swiper.params.offsetSlidesAfter = 0;
				swiper.params.touchRatio = 0.8;
				reInitSwiper();
			},
			OneByOne: function() {
				swiper.params.slidesPerView = 1.1;
				swiper.params.slidesPerGroup = 1;
				swiper.params.centeredSlides = true;
				swiper.params.offsetSlidesBefore = 0;
				swiper.params.offsetSlidesAfter = 0;
				swiper.params.touchRatio = 0.8;
				reInitSwiper();
			},
			reset: function() {
				var $swiper		=	$(swiper.container).find('.swiper'),
					$swipeBox	=	$swiper.find('.box-item');

				swiper.params.autoResize = false;
				swiper.params.slidesPerView = 'auto';
				swiper.params.slidesPerViewFit = false;
				swiper.params.offsetSlidesBefore = 0;
				swiper.params.offsetSlidesAfter = 0;
				swiper.params.touchRatio = 0;

				$swiper.removeAttr('style');
				$swipeBox.removeAttr('style');
				swiper.params.touchRatio = 0;
				
			},
			killTouch: function() {
				swiper.params.touchRatio = 0;
			}
		};

		function reInitSwiper() {
			var $swiper		=	$(swiper.container).find('.swiper'),
				$swipeBox	=	$swiper.find('.box-item');

			$swiper.removeClass('kill-swiper');
			$swiper.removeAttr('style');
			$swipeBox.removeAttr('style');
			swiper.reInit();
		}
		return size;
	}

	//
	// Create swiper for each on page
	function callSwiper() {

		MQWidth();
		currentMQSize = getMQSize();

        // Insert headers for all sliders
		$("div.swiper span.vignette").each(function (index, value) {
			$("div.swiper-mobile-head").append('<span class="label head-slide">' + value.innerHTML + '</span>');
		});

		$('.swiper-container').each(function() {
			topBoxSwiper($(this), true, currentMQSize);
		});

		$('.fulltime-swiper-container').each(function() {
			fullTimeSwiperInit($(this), true, currentMQSize);
		});
		$('.autoheight-swiper-container').each(function () {
		    fullTimeSwiperInit($(this), true, currentMQSize);
		});

		$('.fulltime-swiper-container--wide').each(function() {
			fullTimeSwiperInit($(this), true, currentMQSize);
		});

		$(window).smartresize(function() {
			MQWidth();
		});

		$(document).on('breakpoint', function(e) {
			$.each(allFulltimeSwipers, function() {
				setSwiper(this, currentMQSize);
			});
		});
	}

	function getMQSize() {

		windowWidth = $(window).width();
		var newMQSize;

		if (xLargeBreak < windowWidth && windowWidth > largeBreak) {
			newMQSize = 'xxlarge';
		}else if(largeBreak < windowWidth && windowWidth > mediumBreak) {
			newMQSize = 'xlarge';
		} else if (largeBreak >= windowWidth && windowWidth > mediumBreak) {
			newMQSize = 'large';
		} else if((mediumBreak >= windowWidth && littleMediumBreak < windowWidth)) {
			newMQSize = 'medium';
		} else if (littleMediumBreak >= windowWidth && smallBreak < windowWidth) {
			newMQSize = 'littlemedium';
		} else if ((smallBreak >= windowWidth)) {
			newMQSize = 'small';
		}
		return newMQSize;
	}

	function MQWidth() {
		windowWidth = $(window).width();

		if (currentMQSize !== getMQSize()) {
			currentMQSize = getMQSize();
			$(document).trigger('breakpoint', [currentMQSize]);
		}
	}

	//
	// Set specs for "fulltime swiper
	function fullTimeSwiperInit(swiperContainer, dontCalcHeight) {

		var swiperSettings = {
				wrapperClass: 'swiper',
				slideClass: 'box-item',
				reallyDontCalculateHeight: dontCalcHeight,
				createPagination: true,
				paginationClickable: true,
				pagination: swiperContainer.find('.swiper-pagegination .pagination-inner')[0],
				paginationAsRange: true,
				roundLengths: true,
				onTouchEnd: swipeBegun,
				onSlideChangeStart: swipeBegun,
				onSwiperCreated: setSwiper,
				onInit: swipeBegun,
				fullTimeSwiper: true
			};

		var fulltimeBoxSwiper = new Swiper(swiperContainer[0], swiperSettings);
		allFulltimeSwipers.push(fulltimeBoxSwiper);
		swiperContainer.addClass('swiper-active');
	}

	function setSwiper(swiper, MQWidth) {
		MQWidth = MQWidth !== undefined ? MQWidth : currentMQSize;

		// TEST IF THE CONTAINER IS FOR PRODUCTS (WIDE BOXES SWIPER)
	    var productSwiperWideContainer = $(swiper.container).is('.fulltime-swiper-container--wide');
		var fiveByFiveSwiperContainer = $(swiper.container).is('.fivebyfive-swiper-container');
		var sixBySixSwiperContainer = $(swiper.container).is('.sixbysix-swiper-container');
		

		if(productSwiperWideContainer) {
			productSwiper(swiper, MQWidth);
		}
		else if (fiveByFiveSwiperContainer) {
			fiveByFiveSwiper(swiper, MQWidth); }
		else if (sixBySixSwiperContainer) {
			sixBySixSwiper(swiper, MQWidth);
    } else {
			standardSwiper(swiper, MQWidth);
    }
	}

	function standardSwiper(swiper, MQWidth) {
		var $swiperContainer = $(swiper.container);
		var swiperItemLength = $swiperContainer.find('.box-item').length;


		if (MQWidth === 'xxlarge') {
			if (swiperItemLength <= 4) {
				$swiperContainer.removeClass('js-expanded-width');
				swiperSettings(swiper).FourByFour();
				swiperSettings(swiper).killTouch();
			} else {
				$swiperContainer.addClass('js-expanded-width');
				swiperSettings(swiper).FourByFourWide();
			}
			slideControl(swiper);


		} else if (MQWidth === 'xlarge') {
			$swiperContainer.removeClass('js-expanded-width');
			swiperSettings(swiper).FourByFour();
			slideControl(swiper);

		} else if (MQWidth === 'medium' || MQWidth === 'large') {
			$swiperContainer.removeClass('js-expanded-width');
			swiperSettings(swiper).ThreeByThree();
			slideControl(swiper);

		} else if (MQWidth === 'littlemedium') {
			swiperSettings(swiper).TwoByTwo();
			$swiperContainer.removeClass('js-expanded-width');
			slideControl(swiper);
		} else if (MQWidth === 'small') {
			$swiperContainer.removeClass('js-expanded-width');
			swiperSettings(swiper).OneByOne();
			slideControl(swiper);
		}
	}

	function sixBySixSwiper(swiper, MQWidth) {
		var $swiperContainer = $(swiper.container);
		var swiperItemLength = $swiperContainer.find('.box-item').length;

		if (MQWidth === 'xxlarge') {
			if (swiperItemLength <= 6) {
				$swiperContainer.removeClass('js-expanded-width');
				swiperSettings(swiper).SixBySix();
				swiperSettings(swiper).killTouch();
			} else {
				$swiperContainer.addClass('js-expanded-width');
				swiperSettings(swiper).SixBySixWide();
			}
			slideControl(swiper);
		} else if (MQWidth === 'xlarge') {
			$swiperContainer.removeClass('js-expanded-width');
			swiperSettings(swiper).FiveByFive();
			slideControl(swiper);
		} else if (MQWidth === 'large') {
			$swiperContainer.removeClass('js-expanded-width');
			swiperSettings(swiper).FourByFour();
			slideControl(swiper);
		}
		 else if (MQWidth === 'medium') {
			$swiperContainer.removeClass('js-expanded-width');
			swiperSettings(swiper).FourByFour();
			slideControl(swiper);
		} else if (MQWidth === 'littlemedium') {
			swiperSettings(swiper).ThreeByThree();
			$swiperContainer.removeClass('js-expanded-width');
			slideControl(swiper);
		} else if (MQWidth === 'small') {
			$swiperContainer.addClass('js-expanded-width');
			swiperSettings(swiper).TwoByTwoWide();
			slideControl(swiper);
		}
	}

	function fiveByFiveSwiper(swiper, MQWidth) {
		var $swiperContainer = $(swiper.container);
		var swiperItemLength = $swiperContainer.find('.box-item').length;

		if (MQWidth === 'xlarge' || MQWidth === 'xxlarge') {
			if (swiperItemLength <= 5) {
				$swiperContainer.removeClass('js-expanded-width');
				swiperSettings(swiper).FiveByFive();
				swiperSettings(swiper).killTouch();
			} else {
				$swiperContainer.addClass('js-expanded-width');
				swiperSettings(swiper).FiveByFiveWide();
			}
			slideControl(swiper);
		} else if (MQWidth === 'large') {
			$swiperContainer.removeClass('js-expanded-width');
			swiperSettings(swiper).FiveByFive();
			slideControl(swiper);
			swiperSettings(swiper).killTouch();
		} else if (MQWidth === 'medium') {
			$swiperContainer.removeClass('js-expanded-width');
			swiperSettings(swiper).FiveByFive();
			slideControl(swiper);
		} else if (MQWidth === 'littlemedium') {
			swiperSettings(swiper).ThreeByThree();
			$swiperContainer.removeClass('js-expanded-width');
			slideControl(swiper);
		} else if (MQWidth === 'small') {
			$swiperContainer.removeClass('js-expanded-width');
			swiperSettings(swiper).OneByOne();
			slideControl(swiper);
		}
	}

	function productSwiper(swiper, MQWidth) {

		var $swiperContainer = $(swiper.container);
		var swiperItemLength = $swiperContainer.find('.box-item').length;

		if(MQWidth === 'xlarge' || MQWidth === 'xxlarge') {
			if(swiperItemLength <= 2) {
				swiperSettings(swiper).ThreeByThree();
				swiperSettings(swiper).killTouch();
			} else {
				$swiperContainer.addClass('js-expanded-width');
				swiperSettings(swiper).ThreeByThreeWide();
			}
			slideControl(swiper);
		} else if(MQWidth === 'large') {
			swiperSettings(swiper).ThreeByThree();
			slideControl(swiper);
			$swiperContainer.removeClass('js-expanded-width');
		} else if(MQWidth === 'medium' || MQWidth === 'littlemedium') {
			swiperSettings(swiper).TwoByTwo();
			slideControl(swiper);
			$swiperContainer.removeClass('js-expanded-width');
		} else if(MQWidth === 'small') {
			$swiperContainer.removeClass('js-expanded-width');
			swiperSettings(swiper).OneByOne();
		}
	}

	//
	// Set specs for top swiper (only inited on small screens)
	function topBoxSwiper(swiperContainer, dontCalcHeight) {

		var topBoxSwiperSettings = {
			wrapperClass: 'swiper',
			slideClass: 'box-item',
			slidesPerView: 1.1,
			centeredSlides: true,
			reallyDontCalculateHeight: dontCalcHeight,
			createPagination: true,
			paginationClickable: true,
			pagination: swiperContainer.find('.swiper-pagegination .pagination-inner')[0],
			paginationAsRange: true,
			onTouchEnd: swiperPositionCallback,
			onSlideChangeEnd: swiperPositionCallback,
			onInit: swipeBegun,
			fullTimeSwiper: false,
			onCreated: initTopBoxSwiper
		};

		var swiperBoxHeadSetting = {
			slidesPerView: 'auto',
			wrapperClass: 'swiper-mobile-head',
			slideClass: 'head-slide',
			calculateHeight: true,
			createPagination: false,
			centeredSlides: true,
			onTouchEnd: swiperHeadPositionCallback,
			onSlideClick: swiperHeadClickPosition,
		    // cssWidthAndHeight: 'width'
		};

		var $swiper			=	swiperContainer.find('.swiper'),
			$swipeBox		=	$swiper.find('.box-item'),
			$swiperHead		=	swiperContainer.find('.label.vignette'),
			$swiperBoxHead	=	$swiperHead.find('.head-slide'),
			swiperInited,
			boxSwiper,
			boxHeadSwiper;


		//  TRIGGER ON WINDOW RESIZE
		$(document).on('breakpoint', function() {
			initTopBoxSwiper(getMQSize());
		});

		// RUN ON LOAD
		initTopBoxSwiper(getMQSize());

		// SMALL SWIPER INIT
		function initTopBoxSwiper(MQWidth) {

			if (MQWidth === 'small') {
				topBoxSwiperSettings.slidesPerView = 1.1;
				topBoxSwiperSettings.centeredSlides = true;
				if (!swiperInited) {
					swiperInited = true;
					boxSwiper = new Swiper(swiperContainer[0], topBoxSwiperSettings);
				}
			} else if (MQWidth !== 'small' && MQWidth !== 'xlarge' && MQWidth !== 'xxlarge') {
				topBoxSwiperSettings.slidesPerView = 1.5;
				topBoxSwiperSettings.centeredSlides = false;
				if (!swiperInited) {
					swiperInited = true;
					boxSwiper = new Swiper(swiperContainer[0], topBoxSwiperSettings);
				}

				if($swiperHead.length > 0) {
					boxHeadSwiper = new Swiper(swiperContainer[0], swiperBoxHeadSetting);
					//Calculate width for mobile head according to number of slides / headers
					var headSlideNum = $(".head-slide").length;
					$(".swiper-mobile-head").css("width", "calc(100% + " + headSlideNum * 175 + "px");
				}
			} else {
				if(swiperInited) {
					destroySlideControl(boxSwiper);
					swiperInited = false;
					boxSwiper.destroy(true);
					$swiper.removeAttr('style');
					$swipeBox.removeAttr('style');
					boxSwiper = undefined;

					if($swiperHead.length > 0) {
						boxHeadSwiper.destroy();
						$swiperHead.removeAttr('style');
						$swiperBoxHead.removeAttr('style');
						boxHeadSwiper = undefined;
					}
				}
			}

			if (swiperInited === true) {
				if($swiperHead.length > 0) {
					boxHeadSwiper = new Swiper(swiperContainer[0], swiperBoxHeadSetting);
					// Calculate width for mobile head according to number of slides / headers
					var $headSlides = $(".head-slide");
					var $slidesWidth = $(".swipe-box").width();
					$(".swiper-mobile-head").css("width", "calc(100% + " + $headSlides.length * 175 + "px");
				}
				slideControl(boxSwiper);
				swipeBegun(boxSwiper);
			}
		}

		function destroyWiper(swiper) {
			destroySlideControl(boxSwiper);
			swiperInited = false;
			swiper.destroy(true);
			$swiper.removeAttr('style');
			$swipeBox.removeAttr('style');
			swiper = undefined;
		}

		// HEAD-LABEL FUNCTIONs
		function swiperHeadClickPosition(swiperHead) {
			var clickedSlide = swiperHead.clickedSlideIndex;
			boxSwiper.swipeTo(clickedSlide, 500);
			boxHeadSwiper.swipeTo(clickedSlide, 500);
		}

		function swiperHeadPositionCallback(swiperHead) {
			boxSwiper.swipeTo(swiperHead.activeIndex, 500);
		}
		function swiperPositionCallback(swiper) {
			swipeBegun(swiper);
			if (boxHeadSwiper) {
				boxHeadSwiper.swipeTo(swiper.activeIndex, 500);
			}
		}
	}

	// Display shadow effect, if swipe begun
	function swipeBegun(swiper) {
		var slidePerGroup	= swiper.params.slidesPerGroup,
			totalSlides		= swiper.slides.length,
			activeSlide		= swiper.activeIndex,
			$swiperContainer = $(swiper.container);

		var prev = $swiperContainer.find('.pagination-prev'),
			next = $swiperContainer.find('.pagination-next');

		if (slidePerGroup !== totalSlides && slidePerGroup < totalSlides) {

			$swiperContainer.addClass('js-show-first-effect js-show-last-effect');
			if(activeSlide === 0) {
				$swiperContainer.removeClass('js-show-first-effect');
			} else if (activeSlide === (totalSlides - slidePerGroup)) {
				$swiperContainer.removeClass('js-show-last-effect');
			}

		} else {
			$swiperContainer.removeClass('js-show-first-effect js-show-last-effect');
		}

		//
		// NAV ARROWS
		if(activeSlide === 0) {
			prev.addClass('js-hide');
		} else {
			prev.removeClass('js-hide');
		}

		if (activeSlide === (totalSlides - slidePerGroup)) {
			next.addClass('js-hide');
		} else {
			next.removeClass('js-hide');
		}

	}

	// NEXT/PREV CONTROLS
	function slideControl(swiper) {
		var $swiper = $(swiper.container),
			prev = $swiper.find('.pagination-prev'),
			next = $swiper.find('.pagination-next');

		prev.on('click', function(e) {
			e.preventDefault();
			swiper.swipePrev();
		});
		next.on('click', function(e) {
			e.preventDefault();
			swiper.swipeNext();
		});

		hideInactivePagination(swiper);
	}

	function destroySlideControl(swiper) {
		var $swiper = $(swiper.container),
			prev = $swiper.find('.pagination-prev'),
			next = $swiper.find('.pagination-next');

		prev.unbind();
		next.unbind();
	}


	// CONTROL WHAT PAGINATION-BULLETS ARE SHOWN
	function hideInactivePagination(swiper) {
		var $swiper = $(swiper.container),
			slidePerGroup	= swiper.params.slidesPerGroup,
			totalSlides		= swiper.slides.length,
			wholeSlides 	= Math.ceil(totalSlides / slidePerGroup);

		var $pagination = $swiper.find('.swiper-pagegination');

		if(slidePerGroup > 1) {
			var activeCount = totalSlides - slidePerGroup + 1;

			$pagination.show();

			$swiper.find('.swiper-pagination-switch').css('display', 'none');
			$swiper.find('.swiper-pagination-switch:nth-child(' + slidePerGroup + 'n + 1)').css('display', 'inline-block');

			if (slidePerGroup/totalSlides !== 0) {
				$swiper.find('.pagination-inner span:nth-child(' + activeCount +')').css('display', 'inline-block');
				if(wholeSlides !== (totalSlides/slidePerGroup)) {
					$swiper.find('.pagination-inner span').slice(activeCount).css('display', 'none');
				}
			}

		} else {
			$swiper.find('.pagination-inner span').css('display', 'inline-block');
		}

		if(totalSlides === slidePerGroup || totalSlides < slidePerGroup) {
			$pagination.hide();
		}

	}

	$(document).ready(function () {
	    init();
	});
}();
