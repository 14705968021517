var Tivoli = Tivoli || {};

Tivoli.headerScroll = function () {

    var settings = {
        $headerTheme: '.theme-head-section-small .white-theme'
    };

    var $html = $('html'),
        $headerTheme = $(settings.$headerTheme),
        $window = $(window),
        headerHeight = 150,
        IntroBannerHeight = 150,
        startScroll = 0,
        scrollTop = 0,
        prevScrollTop;

    var smallBreak = Tivoli.settings.width.small,
       littleMediumBreak = Tivoli.settings.width.littlemedium,
       mediumBreak = Tivoli.settings.width.medium,
       largeBreak = Tivoli.settings.width.large,
       windowWidth,
       MQWidthStatus;


    function init() {
        $headerTheme = $('.theme-head-section-small .white-theme');

        setupValues();
        updatePage();

        $window.on('scroll', function() {
            setupValues();
            updatePage();
        });
        updateMQWidth();
        $(window).smartresize(function () {
            updateMQWidth();
        });
    }

    function setupValues() {
        scrollTop = $window.scrollTop();
    }

    function updateMQWidth() {
        windowWidth = $(window).width();
        MQWidth();
        
    }

    function updatePage() {
        setAllElements();

    }

    function setAllElements() {
        if (setScrollTops() !== prevScrollTop && MQWidthStatus != 'small' && !Tivoli.vars.isAnyTouchDevice) {
            setScrollTops();
            animateElements();

            prevScrollTop = $window.scrollTop();

        }
    }

    function setScrollTops() {
        scrollTop = $window.scrollTop();
        return scrollTop;
    }

    function MQWidth() {
        if ((largeBreak < windowWidth && windowWidth > mediumBreak) && MQWidthStatus !== 'xlarge') {
            MQWidthStatus = 'xlarge';
            $(document).trigger('breakpoint');
        } else if ((largeBreak > windowWidth && windowWidth > mediumBreak) && MQWidthStatus !== 'large') {
            MQWidthStatus = 'large';
            $(document).trigger('breakpoint');
        } else if ((littleMediumBreak < windowWidth && mediumBreak > windowWidth) && MQWidthStatus !== 'medium') {
            MQWidthStatus = 'medium';
            $(document).trigger('breakpoint');
        } else if (smallBreak < windowWidth && littleMediumBreak > windowWidth && MQWidthStatus !== 'littlemedium') {
            MQWidthStatus = 'littlemedium';
            $(document).trigger('breakpoint');
        } else if ((smallBreak > windowWidth) && MQWidthStatus !== 'small') {
            MQWidthStatus = 'small';
            $(document).trigger('breakpoint');
        }
    }

    function animateElements() {


        var scrollAnim = (IntroBannerHeight - (scrollTop * 0.5));

        if (Modernizr.prefixed('requestAnimationFrame', window) && !$html.hasClass('IOS7') ) {
            TweenMax.to($headerTheme, 0, {
                height: scrollAnim
            });
        }

        if (scrollTop >= (IntroBannerHeight * 2)) {
            TweenMax.to($headerTheme, 0, {
                height: 0
            });
        }
    }

    $(document).ready(function () {
        init();
    });
    $(document).on("jsonLoaded", function () {
        init();
    });
}();