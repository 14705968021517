var Nimb = Nimb || {};

Nimb.UnSlider = function () {
    var $mediaStripImageRatio = 1.754911132;
    var $resized = false;


    function init() {
        $('.media-strip').unslider({
            initSwipe: true,
            infinite: true,
        });

       
        var valueWithoutPadding =  $('.media-strip-item img').first().width() / $mediaStripImageRatio;
        var valueWithPadding = (valueWithoutPadding * 0.15) + valueWithoutPadding;

        $('.media-strip-item img').css("max-height", valueWithPadding + "px");

        $(window).smartresize(function () {
            if (!$resized) {
                $('.media-strip-item img').css("max-height", "none");
                $resized = true;
            }
        });
    }

    $(document).ready(function () {
        init();
    });

    $(document).on("jsonLoaded", function () {
        init();
    });
}();