var Tivoli = Tivoli || {};

Tivoli.parallaxtop = function () {

    var settings = {
        $wrapper: '.js-parallax-top',
        $content: '.hero-media',
        $pattern: '.hero-pattern',
        $background: '.itemlist-section',
        $contentSibling: '.hero-section + section',
        $scrollOverlay: '.topbanner__scroll-overlay',
        $introLogo: '.outlet-intro-section__logo img'
    };

    var $html = $('html'),
        $wrapper = $(settings.$wrapper),
        $scrollOverlay = $(settings.$scrollOverlay),
        $content = $wrapper.find(settings.$content),
        $introLogo = $(settings.$introLogo),
        $window = $(window),
        oldScrollPos = 0,
        menuHeight = 0,
        windowHeight = 0,
        windowPosition = 0,
        scrollTop = 0,
        prevScrollTop,
        $headerTheme,
        $border,
        $introLogoContainer,
        $introButtons;

    var smallBreak = Tivoli.settings.width.small,
  littleMediumBreak = Tivoli.settings.width.littlemedium,
  mediumBreak = Tivoli.settings.width.medium,
  largeBreak = Tivoli.settings.width.large,
  windowWidth,
  MQWidthStatus;



    function init() {

        $wrapper = $('.js-parallax-top');
        $content = $('.js-parallax-top').find(settings.$content);
        $introLogo = $('.outlet-intro-section__logo img');
        $scrollOverlay = $('.topbanner__scroll-overlay');
        $headerTheme = $('.theme-head-section'),
        $introLogo = $('.outlet-intro-section__logo img'),
        $border = $headerTheme.find('.border'),
        $introLogoContainer = $('.outlet-intro-section__logo'),
        $introButtons = $('.intro-links');

        // NO PARALLAX FOR BROWSERS NOT SUPPORTING ANIMATION FRAME (performance issue)
        if ($wrapper.length === 0) {
             return;
        }

        if (MQWidthStatus != 'small' && $wrapper.length !== 0 && !$html.hasClass('IOS7')) {
            setupValues();
            updatePage();
        }

        if (!Tivoli.vars.isAnyTouchDevice) {
            animateElements();
        }

        updateMQWidth();
        $(window).smartresize(function () {
            updateMQWidth();
        });


        $window.on('scroll', function () {
            //$.throttle(10, function () {
            if (MQWidthStatus != 'small' && $wrapper.length !== 0 && !$html.hasClass('IOS7')) {
                setupValues();
                updatePage();
            }
            //});
        });
    }

    function setupValues() {
        scrollTop = $window.scrollTop();
        windowHeight = $wrapper.height();
        windowPosition = $wrapper.offset().top;
    }

    function updatePage() {
        //window.requestAnimationFrame(function () {
        setAllElements();
        //});
    }

    function updateMQWidth() {
        windowWidth = $(window).width();
        MQWidth();
    }
    function setAllElements() {
        if (setScrollTops() !== prevScrollTop) {
            setScrollTops();
            animateElements();

            prevScrollTop = $window.scrollTop();
        }
    }

    function setScrollTops() {
        scrollTop = $window.scrollTop();
        return scrollTop;
    }

    function animateElements() {
        if (scrollTop <= (windowHeight) && !Tivoli.vars.isIpad) {

            var scrollAnim = +((scrollTop / 0.12) / 4);
            var logoScrollAnim = (110 - scrollTop);
            oldScrollPos = scrollAnim;
            var heightAnim = 800 - scrollTop;


            var bgPositionExtra = 0;
            if ($wrapper.hasClass("position-middle")) {
                bgPositionExtra = 900;
            }
            if ($wrapper.hasClass("position-bottom")) {
                bgPositionExtra = 600;
            }

            TweenMax.to($wrapper, 0, {
                y: scrollTop
            });

            TweenMax.to($scrollOverlay, 0, {
                y: -(scrollAnim * 1.5),
                height: heightAnim
            });


            $('.theme-head-section__logo').css({
                opacity: ((scrollTop / 500))
            });

            if (scrollTop <= 150) {
                $wrapper.css({
                    opacity: 1
                });
            }

            if (scrollTop >= 150) {
                $wrapper.css({
                    opacity: 0
                });
                $scrollOverlay.css({
                    height: 150
                });

            }


            //Buttons and logo animation
            if (400 - scrollTop > 153) {
                TweenMax.to($introLogo, 0, {
                    width: 400 - scrollTop * 1.55
                });
            }

            TweenMax.to($introLogoContainer, 0, {
                y: -(scrollTop * 0.55),
            });

            var buttonOpacity = ((350 / scrollTop) / 100);
            TweenMax.to($introButtons, 0, {
                opacity: buttonOpacity
            });

            if (scrollTop >= 110) {
                if (150 + logoScrollAnim >= 0) {

                    var diff = 150 - (150 + logoScrollAnim);
                    var percent = ((diff / 150) * 100);
                    $border.css({
                        width: percent.toFixed(2) + '%'
                    });
                }
            }

            if (scrollTop == 0) {
                TweenMax.to($introButtons, 0, {
                    opacity: 1
                });
            }
        }

        if (scrollTop >= (300)) {
            $border.css({
                width: '100%',
            });

            $('.theme-head-section__logo').css({
                opacity: 1
            });

        }

        if (scrollTop >= 100) {
            $('.content-container').css({
                zIndex: 50
            });
        }
        if (scrollTop <= 110) {
            $('.content-container').css({
                zIndex: 100
            });
        }

        var scrollTopValue = 250;

        if(Tivoli.vars.isIpad) {
            scrollTopValue = 110;
        }
        
        if (scrollTop < scrollTopValue) {
            $headerTheme.addClass("no-bg");
        } else {
            $headerTheme.removeClass("no-bg");
        }
    }

    function MQWidth() {
        if ((largeBreak < windowWidth && windowWidth > mediumBreak) && MQWidthStatus !== 'xlarge') {
            MQWidthStatus = 'xlarge';
            $(document).trigger('breakpoint');
        } else if ((largeBreak > windowWidth && windowWidth > mediumBreak) && MQWidthStatus !== 'large') {
            MQWidthStatus = 'large';
            $(document).trigger('breakpoint');
        } else if ((littleMediumBreak < windowWidth && mediumBreak > windowWidth) && MQWidthStatus !== 'medium') {
            MQWidthStatus = 'medium';
            $(document).trigger('breakpoint');
        } else if (smallBreak < windowWidth && littleMediumBreak > windowWidth && MQWidthStatus !== 'littlemedium') {
            MQWidthStatus = 'littlemedium';
            $(document).trigger('breakpoint');
        } else if ((smallBreak > windowWidth) && MQWidthStatus !== 'small') {
            MQWidthStatus = 'small';
            $(document).trigger('breakpoint');
        }
    }

    $(document).ready(function () {
        init();
    });

    $(document).on("jsonLoaded", function () {
        init();
    });
}();