Nimb = Nimb || {};

Nimb.newsletter = (function() {
    function init() {
        $.each($('.js-newsletter-signup-form'), function() {
            handleNewsletterSignup($(this));
        });
     
    }

    function handleNewsletterSignup(formContainer) {

        var outerContainer = formContainer.parents(".js-newsletter-form-container");
        var successDiv = outerContainer.find(".form-submit-success");
        var errorDiv = outerContainer.find(".form-submit-failed");

        var button = formContainer.find(".js-custom-submit a");
        var loader = button.find(".js-circle-loader");

        formContainer.on("form-request", function() {
            successDiv.hide();
            errorDiv.hide();
            Tivoli.CircleLoader.loadInitial(loader, null);
        });
        
        formContainer.on("form-success", function (e, data) {
            if (data.Success) {
                successDiv.show();
                errorDiv.hide();
                formContainer.hide();
                dataLayer.push({ 'event': 'newsletter-signup' });
            } else {
                successDiv.hide();
                errorDiv.show();
            }
        });
        
        formContainer.on("form-error", function () {
           successDiv.hide();
            errorDiv.show();
        });
        formContainer.on("error", function () {
            successDiv.hide();
            errorDiv.show();
        });
    };

    $(document).ready(init);

    return {
        
    };
})();