var Nimb = Nimb || {};

Nimb.checkboxes = function () {
    $.fn.customCheckbox = function() {
        var domElements = '<div><span class="unchecked"></span><span class="checked"></span></div>';
        
        this.each(function() {

            var checkbox = $(this);

            checkbox.addClass('custom-checkbox');
            checkbox.after(domElements);


            if(checkbox.siblings('div').height() < checkbox.siblings('label').height()) {
                checkbox.parent().addClass('label-overflow');
            };

            // On click on "checkbox" imitator
            checkbox.siblings('div').on('click', function(e) {
                var input = $(this).siblings(':checkbox');
                e.stopPropagation();
				e.preventDefault();
                clickHandler(input);
            });

            // On click on "checkbox" / or label
            function clickHandler(input) {
                if (!input.prop('checked')) {
                    input.closest('label').addClass('active');
                    input.prop('checked', true);

                } else {
                    input.prop('checked', false);
                    input.closest('label').removeClass('active');
                }
                input.change();
            }

        });
    };

    function init() {
        $(':checkbox').customCheckbox();
    }

    $(document).ready(function () {
        init();
    });

    $(document).on("jsonLoaded", function () {
        init();
    });
}();