Nimb = Nimb || {};

Nimb.dinnerbooking = (function() {
    function init() {
        $('.book-table').click(function () {
            var res = $(this).attr('href').replace('http://', '').split('.')[0];

            dataLayer.push({
                "event": "trackEvent",
                "eventCategory": "Booking",
                "eventAction": "Open",
                "eventLabel": res
            });
        });
    }

   

    $(document).ready(init);

    $(document).on("jsonLoaded", function() {
        init();
    });

    return {
        
    };
})();