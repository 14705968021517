var Tivoli = Tivoli || {};

Tivoli.RoomSwiper = function () {
    var $cn = $('.hotel-room-container'),
        $cntWd = $('.hotel-room-container').innerWidth(),
        $tb = $('.hotel-room-container .hotel-rooms'),
        $sldWd = $tb.outerWidth(),
        $outlets = $('.hotel-room').length - 2,
        $hotelRoomWidth = 270,
        $lastOutletOffsetRight = ($outlets * 330) - $(window).width() + $hotelRoomWidth


    var smallBreak = Tivoli.settings.width.small,
        littleMediumBreak = Tivoli.settings.width.littlemedium,
        mediumBreak = Tivoli.settings.width.medium,
        largeBreak = Tivoli.settings.width.large,
        windowWidth,
        MQWidthStatus;
       

    function init() {
        if (!$('.hotel-room-container').length >= 1) {
            return;   
        }

        if (Tivoli.vars.isAnyTouchDevice || Tivoli.vars.isIpad) {
            $('.hotel-room-container').addClass('isTouch');
        }

        windowWidth = $(window).width();
        MQWidth();
      
        updateOutletVars();
        activateOnMouseMove();
        activateRoomImage();
        
        $(window).smartresize(function () {
            if (!$cn.hasClass('active')) {
                updateOutletVars();
                activateOnMouseMove();
            }
            
        });

    }

    function MQWidth() {
        if ((largeBreak < windowWidth && windowWidth > mediumBreak) && MQWidthStatus !== 'xlarge') {
            MQWidthStatus = 'xlarge';
            $(document).trigger('breakpoint');
        } else if ((largeBreak > windowWidth && windowWidth > mediumBreak) && MQWidthStatus !== 'large') {
            MQWidthStatus = 'large';
            $(document).trigger('breakpoint');
        } else if ((littleMediumBreak < windowWidth && mediumBreak > windowWidth) && MQWidthStatus !== 'medium') {
            MQWidthStatus = 'medium';
            $(document).trigger('breakpoint');
        } else if (smallBreak < windowWidth && littleMediumBreak > windowWidth && MQWidthStatus !== 'littlemedium') {
            MQWidthStatus = 'littlemedium';
            $(document).trigger('breakpoint');
        } else if ((smallBreak > windowWidth) && MQWidthStatus !== 'small') {
            MQWidthStatus = 'small';
            $(document).trigger('breakpoint');
        }
    }

    function updateOutletVars() {
        windowWidth = $(window).width();
        MQWidth();
        $('.hotel-room').width($hotelRoomWidth);
       

        $cn = $('.hotel-room-container'),
        $cntWd = $('.hotel-room-container').innerWidth(),
        $tb = $('.hotel-room-container .hotel-rooms'),
        $sldWd = $tb.outerWidth(),
        $outlets = $('.hotel-room').length - 2,
        $lastOutletOffsetRight = ($outlets * 330) - $(window).width() + $hotelRoomWidth;
    }


    function activateOnMouseMove() {
        if (MQWidthStatus != 'small' && !Tivoli.vars.isAnyTouchDevice && !Tivoli.vars.isIpad) {
            $tb.css({ left: -270 });
            $('.hotel-room-container').mousemove(function (e) {
                var $numOffset = 0;
                var animate = true;
                var num = (((($cntWd - $numOffset) - $sldWd)) * (((e.pageX - $numOffset) / ($cntWd - $numOffset)).toFixed(3)));

                if (num > -270) {
                    animate = false;
                }

                if (num < -Math.abs($lastOutletOffsetRight + 120)) {
                    animate = false;
                }

                if (animate) {
                    TweenMax.to($tb, 0, {
                        left: num.toFixed(1) 
                    });
                }
            });
        } else {
            $tb.css({ left: 0});
            $('.hotel-room-container').unbind();
        }
    }

    function activateRoomImage() {
        MQWidth();

        if (MQWidthStatus != 'small') {
            var timer;
            $('.hotel-room').on({
                'mouseover': function() {
                    var imageUrl = $(this).data("image");

                    timer = setTimeout(function() {
                        var heroMedia = $('.hotel-room-section .media-box');
                        heroMedia.before('<div class="media-box"><img class="hero-media" src="' + imageUrl + '" /></div>');
                        TweenMax.to(heroMedia, 0.3, {
                            opacity: 0,
                            onComplete: function() {
                                heroMedia.remove();
                            }
                        });
                    }, 400);
                },
                'mouseout': function() {
                    clearTimeout(timer);
                }
            });
        }
    }

    $(document).ready(function () {
        init();
    });

    $(document).on("jsonLoaded", function () {
        init();
    });

}();