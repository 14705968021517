var Tivoli = Tivoli || {};

Tivoli.basketButton = function () {

    function init() {
        if ($('.large-basket-container').length === 0) {
            return;
        }

        $('.basket-button').each(function() {
            TweenMax.to($(this), 0.4, {
                delay: 0.6,
                transform: 'scale(1.8)',
                yoyo: true,
                repeat: 1,
                ease: Circ.easeIn
            });
        });
    }


    $(document).ready(function () {
        init();
    });
   
}();