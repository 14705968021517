var Tivoli = Tivoli || {};

Tivoli.navigation = function () {
    var $html = $('html'),
		$body = $('body'),
		$navSection = $('#nav-section'),
		$siteContainer = $('#site-container'),
		$bodyhtml = $('body,html'),
		$exitNavOverlay = $('.site-container-overlay'),
		navWidth = 256,
		menuTopHeight = 80,
		windowWidth = $(window).width(),
		menuBreakWidth = Tivoli.settings.width.xlarge;

	var slideOutRightClass = 'slideout-right-is-active',
		menuIsActiveClass = 'menu-is-active',
        menuIsHoverClass = 'menu-is-hover',
		contextNavActiveClass = 'context-nav-is-active';

    function init() {
        if ($navSection.length <= 0) {
            return;
        }
		multiLayerMenu();
		menuAnchorLinks();
		menuActivation();
        menuHoverActivation();
		closeMenuOnClick();
		testIfMenuIsOpen();
		asideContentActivation();
		exitAsideContent();
		scrollFocusing();
		externalAnchorLinks();
        subMenuActivation();


		$(window).smartresize(function() {
			windowWidth = $(window).width();
			testIfMenuIsOpen();
		});
    }

	function scrollFocusing() {

		var selScrollable = '.scroll-wrapper';

		// Uses body because jQuery on events are called off of the element they are
		// added to, so bubbling would not work if we used document instead.
		$('body').on('touchstart', selScrollable, function(e) {
			if (e.currentTarget.scrollTop === 0) {
				e.currentTarget.scrollTop = 1;
			} else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
				e.currentTarget.scrollTop -= 1;
			}
		});
		// Stops preventDefault from being called on document if it sees a scrollable div
		$('body').on('touchmove', selScrollable, function(e) {
			e.stopPropagation();
		});
	}

	function testIfMenuIsOpen() {

		if(menuBreakWidth > windowWidth) {
			$(document).trigger('menuOpen');
			if ($html.hasClass(menuIsActiveClass)) {
				menuToggle();
			}
		}
	}

	function asideContentToggle() {
		if ($html.hasClass(slideOutRightClass)) {
			$html.removeClass(slideOutRightClass);

		} else {
			$html.addClass(slideOutRightClass);
			$(document).trigger('asideMenuOpen');
			//$siteContainer.css({
			//	//transform: 'translateZ(0)',
			//	//MozTransform: 'translateZ(0)',
			//	//WebkitTransform: 'translateZ(0)',
			//	//msTransform: 'translateZ(0)'
			//});

		}
	}

    function asideContentClose() {
        if ($html.hasClass(slideOutRightClass)) {
            $html.removeClass(slideOutRightClass);
        }
    }

    function asideContentActivation() {
		var newsLetterTrigger = $('.js-slideout-right');
		var activated;
		var slideoutRight = $('.slideout-right-section');

		if (slideoutRight.length === 0) {
		    newsLetterTrigger.css('cursor', 'default');
	    }

		newsLetterTrigger.on('click',function (e) {
		    e.preventDefault();
		    
		    if (slideoutRight.length === 0) {
		        return;
		    }

		    if(!activated) {
		    	activated = true;
		    	setTimeout(function(){ activated = false; }, 150);
		    	asideContentToggle();
                if($(this).hasClass('is-menu-link')) {
		            menuToggle();
		        }
		    }
		});
	}

	function subMenuActivation() {
	    $(".main-nav .menu-expand").on("click", function () {
	        var expanderId = $(this).data("expander-id");
	        var containerToExpand = $("[data-expand-id=" + expanderId + "]");

	        if (!containerToExpand.hasClass("closed")) {
	            TweenMax.set(containerToExpand, { height: "auto" });
	            TweenMax.to(containerToExpand, 0.2, { height: 0 });
	            $(this).addClass("closed");
	            containerToExpand.addClass("closed");
	        } else {
	            TweenMax.set(containerToExpand, { height: "auto" });
	            TweenMax.from(containerToExpand, 0.2, { height: 0 });
	            $(this).removeClass("closed");
	            containerToExpand.removeClass("closed");
	        }
	    });

	    $(".menu-expand").hover(function () {
	        $(this).siblings("a").addClass("fake-hover");
	    }, function () {
	        $(this).siblings("a").removeClass("fake-hover");
	    });
	}

	function menuToggle() {
        //Removes hover-class
        

        if ($html.hasClass(menuIsActiveClass)) {
            $html.removeClass(menuIsActiveClass);
            $(document).trigger('menuClose');

        } else {
            $html.addClass(menuIsActiveClass);
			$(document).trigger('menuOpen');
			var totalBreadCrumbWidth = $('.menu-breadcrumb').width() + 100;
			$(".menu-breadcrumb").animate({
				scrollLeft: totalBreadCrumbWidth
			}, 2000);
			
        }
        $html.removeClass(menuIsHoverClass);
    }

    function menuHoverToggle() {

        if ($html.hasClass(menuIsHoverClass)) {
            $html.removeClass(menuIsHoverClass);
            //$(document).trigger('menuClose');

        } else {
            $html.addClass(menuIsHoverClass);
            //$(document).trigger('menuOpen');
        }
    }

	// MENU BUTTON ACTIVIATION
    function menuActivation() {
        var $menuBtn = $('.menu-button');
		var activated;
		

		$menuBtn.on('click touchend', function (e) {
			e.preventDefault();
			if(!activated) {
				activated = true;
				setTimeout(function(){ activated = false; }, 300);
				menuToggle();
			}
		});

		return false;
    }

    function menuHoverActivation() {
        var $menuBtn = $('.menu-button');
        var activated;
     
        $menuBtn .mouseenter(function() {
            $html.addClass(menuIsHoverClass);
        }).mouseleave(function (e) {
            $html.removeClass(menuIsHoverClass);
        });

        return false;
    }

	// EXIT MENU or ASIDE CONTENT
	function exitAsideContent() {
		var activated;

		$exitNavOverlay.on('click touchstart', function(e) {
			if(!activated) {
				activated = true;
				setTimeout(function(){ activated = false; }, 300);

				if($html.hasClass(menuIsActiveClass)) {
					menuToggle();
				} else {
				    asideContentClose();
				}
			}
		});
	}

	// CLOSE MENU, WHEN MENU-LINKS ARE CLICKED
	function closeMenuOnClick() {
		var $menuItem = $('.main-nav, .sup-main-nav').find('a').not('a[href*=#]');

		$menuItem.on('click', function(e) {
			if (menuBreakWidth > windowWidth) {
				e.preventDefault();
				var href = $(this).attr('href');
				menuToggle(); // CLOSE MENU, WHEN "NORMAL" MENU-ITEM IS CLICKED
				setTimeout(function() {
					window.location = href; // Wait until animation has been set
				}, 600);
			}
		});
	}

	function menuAnchorLinks() {
		var navAnchorLink = $('.main-nav li').find('a[href*="#"]');
		var currentPath = window.location.href.split('#')[0];


		var anchorTarget;
		var animationLength = 550;
		var anchorAnimRunning;


		navAnchorLink.on('click', function(e) {
			var link       = $(this).attr('href');
			var linkArray  = link.split('#');
			var navId      = (typeof linkArray[1] !== 'undefined') ? linkArray[1] : null;
			var targetPath = linkArray[0];

			e.preventDefault();


			if(targetPath === currentPath && navId || targetPath === "") {
				scrollTarget('#' + navId);
			} else if (navId) {
				e.preventDefault();
				window.location = targetPath+'#'+navId;
			}

			function scrollTarget(whereTo) {
				anchorTarget = (Math.abs($body.offset().top)) + ($(whereTo).offset().top - menuTopHeight);
			}


			if(!anchorAnimRunning) {
				anchorAnimRunning = true;
				$bodyhtml.animate({
					scrollTop: anchorTarget
				}, animationLength, function() {
					$html.removeClass('menu-is-active');
					anchorAnimRunning = false;
				});
			}
		});
	}

    function externalAnchorLinks() {
        var anchorTarget;
        var animationLength = 1;

        var link = window.location + '';
        var linkArray = link.split('#');
        var navId = (typeof linkArray[1] !== 'undefined') ? linkArray[1] : null;
        

        
        

        if (navId) {
            var targetId = $("#" + navId).length !== 'undefined' ? $("#" + navId) : '';

            if (targetId.length <= 0) {
                return;
            }
            
            anchorTarget = (Math.abs($body.offset().top)) + ($('#' + navId).offset().top - menuTopHeight);

            $bodyhtml.animate({
                scrollTop: anchorTarget
            }, animationLength, function() {
            });
        }
    }



	function menuSize() {
		if (!$html.hasClass('menu-is-active') && Tivoli.vars.windowWidth < menuBreakWidth) {
			return 0;
		}
		return navWidth;
	}



	// SCROLL menu TO TOP when INTERACTING
	function scrollMenuToTop() {
		var scrollAbleArea = $('.menu-is-active .scroll-wrapper');
		scrollAbleArea.animate({
			scrollTop: 61 // SCROLL to BUTTON OF SEARCH INPUT
		}, 200);
	}
	//
	// STAGGER EFFECT IN MENU
    function multiLayerMenu() {
		var secondaryMenus = $('.sup-main-nav.is-active');
		var mainMenu = $('.main-nav');
		var mainMenuItems = mainMenu.find('a, span, .aside-menu');
		var secondaryMenuTriggers = $('.secondary-menu li').find('a, span');
	  	var navigationTimeLength = 0.3;
		var prevSupSelected;
		var animating;

		secondaryMenuTriggers.on('click touchstart', function(e) {
			e.preventDefault();
			var clickedDataLabel = $(this).data('nav');
			var activatedMenu = $('.sup-main-nav[data-nav=' + clickedDataLabel + ']');
			var activatedMenuItems = activatedMenu.find('a, span');
			var homepageIsActive = $('.js-active-page');

			// DISABLE CLICK IF SELECTED AND CURRENT PAGE IS FRONT-/HOME PAGE
			if($(this).is('.js-active-page.is-active')) {
				return;
			}

			if(animating) {
				e.stopPropagation();
				return;
			}

			animating = true;

			function completeAnimation() {
				setTimeout(function() {
					animating = false;
				}, 500)
			}

			if(animating) {

				// ANIMATE SUP LEVEL MENUS OUT (AND MAIN IN)
				if($(this).hasClass('is-active') || $(this).is('.js-active-page')) {

					// SCROLL TO TOP (minus search field)
					//scrollMenuToTop(); DOES NOT WORK WITHOUT NAVIGATION-SEARCH-SECTION

					// Set the active menu to the offset (after animation is completed)
					function setContainer() {
						// Remove active class from menu
						$('.sup-main-nav').removeClass('is-active');

						TweenMax.set(activatedMenu, { x: -navWidth, clearProps: "all"});
					}

					// Animate menu items out (and set the offset after)
					TweenMax.staggerTo(activatedMenuItems, navigationTimeLength, { x: -navWidth, clearProps: "all"}, 0.05);

					// ANIMATE MAIN MENU IN
					TweenMax.staggerTo(mainMenuItems, navigationTimeLength, { x: 0, clearProps: "all" }, 0.05, setContainer);

					// REMOVE ACTIVE CLASS from LINK
					secondaryMenuTriggers.removeClass('is-active');

					// IF HOME PAGE-button IS CLICKED, SHOW STANDARD MENU
					if(homepageIsActive) {
						var activeSupMenu = $('.sup-main-nav.is-active');
						homepageIsActive.addClass('is-active');

						// Set the active menu to the offset (after animation is completed)
						function setParentContainer() {
							TweenMax.set(activeSupMenu, { x: -navWidth,  clearProps: "all"});
							// Remove active class from menu
							secondaryMenus.removeClass('is-active');
							completeAnimation();
						}

						// Animate menu items out (and set the offset after)
						TweenMax.staggerTo(prevSupSelected, navigationTimeLength, { x: -navWidth, clearProps: "all"}, 0.05, setParentContainer);
					}
				}
				// ANIMATE SUP LEVEL MENUS IN
				else {
					// SCROLL TO TOP (minus search field)
					//scrollMenuToTop(); DOES NOT WORK WITHOUT NAVIGATION-SEARCH-SECTION

					// Set CLASS'es ON TRIGGERs
					secondaryMenuTriggers.removeClass('is-active');
					$(this).addClass('is-active');

					// CHECK IF OTHERS ARE ACTIVE, AND ANIMATE THEM OUT!
					var activeSupMenu = $('.sup-main-nav.is-active');

					function setPrevContainer() {
						// SET CONTAINER of PREVIOUS SELECTED
						TweenMax.set(activeSupMenu, { x: navWidth, clearProps: "all"});
						// RUN others in
						animateItemsIn();
					}

					if(activeSupMenu.length > 0) {
						TweenMax.staggerTo(prevSupSelected, navigationTimeLength, { x: navWidth, clearProps: "all"}, 0.05, setPrevContainer );
					}

					// ANIMATE NEXT IN
					function animateItemsIn() {
						// ANIMATE MAIN MENU OUT
						TweenMax.staggerTo(mainMenuItems, navigationTimeLength, { x: navWidth }, 0.05);

						// Set next sublevel item-container to 0 from left
						TweenMax.set(activatedMenu, { x: 0 });

						// Animate menu items in
						TweenMax.staggerTo(activatedMenuItems, navigationTimeLength, { x: 0 }, 0.05, completeAnimation);

						// Set active class'es
						activeSupMenu.removeClass('is-active');
						activatedMenu.addClass('is-active');
						prevSupSelected = activatedMenuItems; // Set the items of the currently selected, to use when animating out

					}
					animateItemsIn();
				}
			}
		});


		var $subNavLinkItems = $('b.js-sub-navn-link');
        $subNavLinkItems.on('click', function(e) {
            e.preventDefault();
            window.location = $(this).attr('href');
        });
    }

    $(document).ready(function () {
        init();
    });

    return {
        size: menuSize
    };
}();