Nimb = Nimb || {};

Nimb.dinnerbooking = (function () {
    var $slhContainer = $('.slh-booking'),
        $slhOverlay = $('.site-container-overlay');

    function init() {
        $slhContainer = $('.slh-booking'),
        $slhOverlay = $('.site-container-overlay');

        $('.book-room').click(function (event) {
            event.preventDefault();

            openSlhOverlay();
        });

        $.each($('.js-slh-booking-form'), function () {
            handleSlhBookingForm($(this));
        });

    }

    function openSlhOverlay() {
        $slhContainer.addClass("slh-booking--enabled");
        $slhOverlay.addClass("site-container-overlay--enabled");

        if ($slhContainer.css('position') == "absolute") {
            $('body,html').animate({
                scrollTop: 0
            }, 550, function () {
            });
        }


        $('.site-container-overlay--enabled').click(function (event) {
            closeSlhOverlay();
        });

        $('.slh-close').click(function (event) {
            event.preventDefault();
            closeSlhOverlay();
        });


    }

    function closeSlhOverlay() {
        $slhContainer.removeClass("slh-booking--enabled");
        $slhOverlay.removeClass("site-container-overlay--enabled");
    }

    function handleSlhBookingForm(formContainer) {
        

        //formContainer.on("form-request", function (e) {
        formContainer.submit(function (e) {
            e.preventDefault();
            var mainProductDatePicker = $slhContainer.find(".js-product-date").siblings('.datepicker-simple-inner');
            var mainProductDate = mainProductDatePicker.datepicker("getDate");

            var nights = $('.slh-nights').val();
            var adults = $('.slh-adults').val();
            var children = $('.slh-children').val();
            var language = $('.secondary-menu .is-selected').data('language');

            var bookingHref = "https://be.synxis.com/?chain=22402&hotel=78378&src=24C&filter=HOTEL";
            if($('.secondary-menu .is-selected').data('language') == "DA") {
                bookingHref = bookingHref + "&locale=da-DK";
            } else {

                bookingHref = bookingHref + "&locale=en-US";
            }

            bookingHref = bookingHref + "&arrive=" + formatDateToSlhFormat(mainProductDate);
            bookingHref = bookingHref + "&nights=" + nights;
            bookingHref = bookingHref + "&adult=" + adults;

            if(children != "0") {
                bookingHref = bookingHref + "&child=" + children;
            }

            window.location.href = bookingHref;
            
        });
    };

    function formatDateToSlhFormat(date) {
        if (!date) {
            return null;
        }
        var pad = function (num) {
            var norm = Math.abs(Math.floor(num));
            return (norm < 10 ? '0' : '') + norm;
        };
        return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate());
    }


    $(document).ready(init);

    $(document).on("jsonLoaded", function () {
        init();
    });

    return {

    };
})();