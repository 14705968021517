"use strict";

var Tivoli = Tivoli || {};


Tivoli.ducksuite = (function () {

    function setupDuckSuite() {
        if ($('#ducksuite_script').length === 0) {
            return;
        }
        if (window.addEventListener) window.addEventListener("load", downloadDuckSuite, false); else if (window.attachEvent) window.attachEvent("onload", downloadDuckSuite); else window.onload = downloadDuckSuite;
    }
    function downloadDuckSuite() {
        var duckSuiteId = $('#ducksuite_script').data('ducksuite-id');
        $.getScript("//app.ducksuite.com/assets/ducksuite_widget.js", function(data, textStatus, jqxhr) {
            var ducksuite = new DucksuiteWidget(duckSuiteId, "slideshow", "ducksuite_container", document.location.hash);
            ducksuite.setupWidget(true);
        });
    }

    $(document).ready(function () {
        setupDuckSuite();
    });


    $(document).on("jsonLoaded", function () {
        downloadDuckSuite();
    });
})();