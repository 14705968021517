var Tivoli = Tivoli || {};

Tivoli.dateUtil = {
    parseDate: function (input) {
        if (!input) {
            return null;
        }
        var parts = input.split('-');
        return new Date(parts[0], parts[1] - 1, parts[2]); // Note: months are 0-based
    },
    formatLocalDate: function (date) {
        if (!date) {
            return null;
        }

        var tzo = 0 //Force time zone offset to be zero, because it doesn't matter where the user is located. It will only cause us to use the wrong date.
            dif = tzo >= 0 ? '+' : '-',
            pad = function (num) {
                var norm = Math.abs(Math.floor(num));
                return (norm < 10 ? '0' : '') + norm;
            };
        return date.getFullYear()
            + '-' + pad(date.getMonth() + 1)
            + '-' + pad(date.getDate())
            + 'T' + pad(date.getHours())
            + ':' + pad(date.getMinutes())
            + ':' + pad(date.getSeconds())
            + dif + pad(tzo / 60)
            + ':' + pad(tzo % 60);
    },
    formatLocalDateShort: function (date) {
        if (!date) {
            return null;
        }
        var pad = function (num) {
            var norm = Math.abs(Math.floor(num));
            return (norm < 10 ? '0' : '') + norm;
        };
        return pad(date.getDate())
        + '-' + pad(date.getMonth() + 1)
        + '-' + date.getFullYear();
    },
    formatLocalDateShortInverted: function (date) {
        if (!date) {
            return null;
        }
        var pad = function (num) {
            var norm = Math.abs(Math.floor(num));
            return (norm < 10 ? '0' : '') + norm;
        };
        return pad(date.getFullYear())
        + '-' + pad(date.getMonth() + 1)
        + '-' + pad(date.getDate());
    }
};

Tivoli.datePickerControls = (function () {

    var dateFormat = "d. M yy";

    var datePickerSettings = {
        altField: ".datepicker",
        altFormat: dateFormat,
        minDate: 0,
        showOtherMonths: true,
        selectOtherMonths: true
    };

    function init() {
        jQuery.datepicker.regional['da'] = { closeText: 'Luk', prevText: '&#x3C;Forrige', nextText: 'Næste&#x3E;', currentText: 'Idag', monthNames: ['Januar', 'Februar', 'Marts', 'April', 'Maj', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'December'], monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'], dayNames: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'], dayNamesShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'], dayNamesMin: ['S', 'M', 'T', 'O', 'T', 'F', 'L'], weekHeader: 'Uge', dateFormat: 'dd-mm-yy', firstDay: 1, isRTL: false, showMonthAfterYear: false, yearSuffix: '' };
        jQuery.datepicker.regional['en'] = { closeText: 'Done', prevText: 'Prev', nextText: 'Next', currentText: 'Today', monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'], monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'], dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'], dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'], weekHeader: 'Wk', dateFormat: 'dd/mm/yy', firstDay: 0, isRTL: false, showMonthAfterYear: false, yearSuffix: '' };
        jQuery.datepicker.setDefaults(jQuery.datepicker.regional[Tivoli.getCurrentLanguage()]);


        dateRangePicker();
        datePicker();
        closeDatePicker();
    }

    function closeDatePicker() {
        // IF CLICKED OUTSIDE DATEPICKER, CLOSE IT
        var datePickerAreas = $('.datepicker-simple, .daterangepicker');

        $(document).mouseup(function (e) {
            if (!datePickerAreas.is(e.target) && datePickerAreas.has(e.target).length === 0) {
                datePickerAreas.parents().find('.datepicker, .daterangepicker-triggerbutton').removeClass('datepicker-active daterangepicker-active');
            }
        });
    }

    function handleDateRestriction(input, settings) {
        var noWeekends = input.data("no-weekends");

        var datesStr = input.data("restrict-dates");
        var restrictDates = null;
        if (datesStr) {
            restrictDates = datesStr.split(",");
        }
        var minFromStr = input.data("min-from");
        var maxToStr = input.data("max-to");
        if (minFromStr) {
            settings.minDate = Tivoli.dateUtil.parseDate(minFromStr);
        }
        if (maxToStr) {
            settings.maxDate = Tivoli.dateUtil.parseDate(maxToStr);
        }


        if (restrictDates != null && restrictDates.length > 0) {
            settings.beforeShowDay = function noWeekendsAndRestrictedDdates(date) {
                var noWeekend = $.datepicker.noWeekends(date);
                if (noWeekend[0] || !noWeekends) {
                    for (i = 0; i < restrictDates.length; i++) {
                        var testDate = Tivoli.dateUtil.parseDate(restrictDates[i]);
                        if(testDate != null) {
                            if (date.getMonth() == testDate.getMonth() &&
                                date.getDate() == testDate.getDate() &&
                                date.getFullYear() == testDate.getFullYear()) {
                                return [false];
                            }
                        }
                    }
                } else {
                    if (noWeekends) {
                        return noWeekend;
                    }
                }

                return [true];
            }
        } else {
            if (noWeekends) {
                settings.beforeShowDay = $.datepicker.noWeekends;
            }
        }
    }

    function handleEducationRanges(input, settings) {
        var availableDates = OpenDates;
        var originalItem = $('.js-form-rendering-id').val();
        var minFromStr = input.data("min-from");
        var maxToStr = input.data("max-to");
        if (minFromStr) {
            settings.minDate = Tivoli.dateUtil.parseDate(minFromStr);
        }
        if (maxToStr) {
            settings.maxDate = Tivoli.dateUtil.parseDate(maxToStr);
        }

        settings.beforeShowDay = function (date) {
            if (availableDates.indexOf(Tivoli.dateUtil.formatLocalDateShortInverted(date)) >= 0) {
                return [true, ''];
            }
            return [false, ''];
        };

        settings.onSelect = function (date) {
            Tivoli.education.getEducationTimes(date, originalItem);
            

            var datePickerAreas = $('.datepicker-simple, .daterangepicker');
            
            datePickerAreas.parents().find('.datepicker, .daterangepicker-triggerbutton').removeClass('datepicker-active daterangepicker-active');
        }

        Tivoli.education.getEducationTimes(minFromStr, originalItem);
    }

    function datePicker() {
        $.each($('.datepicker'), function () {
            var datePickerInput = $(this);
            if (datePickerInput.hasClass("ios")) {
                return 
            } else {            
                datePickerInput
                    .wrap('<div class="datepicker-simple"></div>')
                    .after('<span class="ui-datepicker-trigger"></span>')
                    .after('<div class="datepicker-simple-inner"></div>');

                var datePickerArea = $(this).siblings('.datepicker-simple-inner');
                

                var settings = $.extend(true, {}, datePickerSettings);

                if ($(this).hasClass('js-education-datepicker')) {
                    handleEducationRanges(datePickerInput, settings);
                } else {
                    handleDateRestriction(datePickerInput, settings);
                }

                datePickerArea.datepicker(settings);

                datePickerInput.on('click', function (e) {
                    e.preventDefault();
                    $(this).addClass('datepicker-active');
                });

                $(this).siblings('.ui-datepicker-trigger').on('click', function () {
                    datePickerInput.addClass('datepicker-active');
                });

                datePickerArea.on('change', function () {
                    datePickerInput.removeClass('datepicker-active');
                });
            }
        });
    }



    function dateRangePicker() {

        $('.daterange').each(function () {

            var input = $(this);

            
            var dateRangeOptions = {
                initialText: input.data("text"),                
                datepickerOptions: {
                    numberOfMonths: 1,
                    minDate: 0,
                    maxDate: 1825,
                    firstDay: 1,
                    dayNamesMin: this.dayNames,
                    showOtherMonths: true,
                    selectOtherMonths: true,
                },
                dateFormat: dateFormat,
                presetRanges: false,
                
                
            };

            handleDateRestriction(input, dateRangeOptions.datepickerOptions);
            $(this).daterangepicker(dateRangeOptions);
            handleDateRangeUrlParameters($(this));
        });
    }

    function handleDateRangeUrlParameters(dateRangePicker) {

        var queryFromDate = (location.search.split("fromdate" + '=')[1] || '').split('&')[0];
        if(queryFromDate == '') {
            return;
        }

        queryFromDate = queryFromDate.split('-');
        var from = new Date(queryFromDate[2],queryFromDate[1]-1,queryFromDate[0]); 

        var queryToDate = (location.search.split("todate" + '=')[1] || '').split('&')[0];
        if(queryToDate != '') {
            queryToDate = queryToDate.split('-');
            var to = new Date(queryToDate[2],queryToDate[1]-1,queryToDate[0]); 
        } else {
            var to = new Date(from);
        }

        $(dateRangePicker).daterangepicker("setRange", {start: from, end: to});
    }

    $(document).ready(function () {
        if ($(".daterange, .datepicker").length === 0) {
            return;
        }
        init();
    });

})();