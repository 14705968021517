var Nimb = Nimb || {};
Nimb.Shop = Nimb.Shop || {};

Nimb.Shop.Tracking = function () {

    function init() {
        loadConsent();
        setTimeout(function(){addCrossDomainTracking( true );}, 500 )
    };

    function loadConsent() {
        window.addEventListener('CookieInformationConsentGiven', function (event) {
            if (CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
                Nimb.Cookies.set('sat_track', true, 24);
            }
            else {
                Nimb.Cookies.set('sat_track', false, 24);
            }
            if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
                Nimb.Cookies.set('sat_track_marketing', true, 24);
            }
            else {
                Nimb.Cookies.set('sat_track_marketing', false, 24);
            }
        }, false);
    }

    function addCrossDomainTracking(initalRun){
        initalRun = typeof initalRun !== 'undefined' ? initalRun : false;
        try{
            if(typeof(window) != "undefined" && typeof(window.Visitor) != "undefined" && typeof(adobe_mcoid) != "undefined"){
                var visitor = window.Visitor.getInstance(adobe_mcoid, {overwriteCrossDomainMCIDAndAID: true}); 
                for(var i = 0; i < adobe_domains.length; i++ ){
                    var domain = adobe_domains[i]
                    var outboudLinks = jQuery('a[href*="' + domain + '"]').not('[href*="adobe_mc="]');
                    for(var j = 0; j < outboudLinks.length; j++){
                        var link = outboudLinks[j];
                        var currentURL=jQuery(link).attr("href")
                        jQuery(link).attr("href",visitor.appendVisitorIDsTo(currentURL))
                    }
                }
            } else if(initalRun) {
                setTimeout(function(){addCrossDomainTracking();}, 500 )
            }
        }catch(err){
        }
    }

    $(document).ready(function () {
        init();
    });

    return {
        addCrossDomainTracking: addCrossDomainTracking
    };

}();