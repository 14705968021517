var Nimb = Nimb || {};

Nimb.Cookies = (function () {
    var set = function(cname, cvalue, exhours) {
        var d = new Date();
        d.setTime(d.getTime() + (exhours * 3600000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + ((exhours === 0) ? "" : (expires + "; path=/"));
    };
    var get = function(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) == 0)
                return c.substring(name.length, c.length);
        }
        return "";
    };

    return {
        set: set,
        get: get,
    };
})();