var Tivoli = Tivoli || {};

Tivoli.dropdowns = function () {


	function init() {
		var selects = $('select').not('.disable-selectric');

		if (selects.length === 0) {
			return;
		}

		selects.selectric({
			arrowButtonMarkup: '<b class="selectric-button"></b>',
			disableOnMobile: true,
			onInit: lookForDropDownImages
		});
		selects.on("change", function () {
			setSelect(this);
			$(this).trigger('blur'); // Make sure JQUERY VALIDATE sees the updated value (and validates it)
		})
	}

	function lookForDropDownImages() {

		var select = $(this);
		var selectricItems = select.closest('.selectricWrapper').find('li');
		var option = select.find('option');

		for (var i = 0; i < option.length; i++) {
			if (option.eq(i).attr('data-imagesrc') !== '') {

				var imageRef = option.eq(i).data('imagesrc') !== undefined ? option.eq(i).data('imagesrc') : 'none';

				if (imageRef !== 'none') {
					selectricItems.eq(i).addClass('select-icon');
					selectricItems.eq(i).attr('style', 'background-image: url("' + imageRef + '");');
				}
			}
		}
		removeEmptyValues(select);
	}

	function removeEmptyValues(select) {
		var selectricItems = select.closest('.selectricWrapper').find('li');
		if (select.data('required')) {
			var optionValue = select.find('option');

			optionValue.each(function () {
				if ($(this).val() === '') {
					var emptyIndex = $(this).index();
					selectricItems.eq(emptyIndex).hide();
				}
			});
		}
	}

	function setSelect(ele) {
		var select = $(ele);
		var selectricButton = select.closest('.selectricWrapper').find('.selectric');
		var selectedOption = select.find(':selected');
		var selectedImage = selectedOption.data('imagesrc') !== undefined ? selectedOption.data('imagesrc') : '';

		if (selectedImage !== '') {
			selectricButton.addClass('selectric-has-icon');
			selectricButton.attr('style', 'background-image: url("' + selectedImage + '");');
		} else {
			selectricButton.removeClass('selectric-has-icon');
			selectricButton.attr('style', '');
		}
	}

	$(document).ready(function () {
		init();
	});
}();