"use strict";

var Tivoli = Tivoli || {};


Tivoli.fullmap = (function () {

    var scrollOverflowContainer,
        scrollContainer,
        container,
        innerContainer,
        map,
        center,
        markers = [],
        currentPosition = null,
        offsetLat = -0.0004;

    function setupSimpleMap() {
        var c = $(".map-section");
        if (c.length === 0) {
            return;
        }
        container = c;

        $('.map-section').lazyLoadGoogleMaps(
        {
            api_key: false,
            language: $(c).data("map-language"),
            scrollOffset: 300,
            callback: function () {
                currentPosition = center;

                getBaseContainers();
                createSimpleMap();
                styleMap(map);

                getLocations(container);

                if (Tivoli.settings.width.small >= $(window).width()) {
                    map.setOptions({ minZoom: 17, maxZoom: 17, zoom: 17 });
                }
                $(window).resize(function () {
                    google.maps.event.trigger(map, "resize");
                    map.setCenter(center);
                });
            }
        });
    }

    function createMarker(markerImage, latitude, longitude) {
        var location = new window.google.maps.LatLng(latitude, longitude);
        var marker = new window.google.maps.Marker({
            position: location,
            clickable: false,
            map: map,
            icon: markerImage
        });
        marker.setZIndex(1);

        markers.push(marker);
    }

    function getBaseContainers() {
        scrollOverflowContainer = $("html");
        scrollContainer = $("#content-container");
        innerContainer = $(".map-section__inner-container");
    }

    function createSimpleMap() {
        var myOptions = {
            center: center,
            zoomControl: false,
            panControl: false,
            zoom: 18,
            draggable: false,
            disableDefaultUI: true,
            scrollwheel: false,
            minZoom: 17,
            maxZoom: 17,
            mapTypeControl: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            disableDoubleClickZoom: true
        };

        map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
    }

    function styleMap(m) {
        m.set('styles', [{ "featureType": "poi", "elementType": "geometry.fill", "stylers": [{ "visibility": "on" }] }, { "featureType": "poi", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "road.local", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.local", "elementType": "geometry.stroke" }]);
    }

    function getLocations(dataContainer) {
        var longitude = dataContainer.data("map-longitude");
        var latitude = dataContainer.data("map-latitude");
        var markerImage = dataContainer.data("map-marker");
        if (dataContainer.hasClass("map-section--true-center")) {
            center = new window.google.maps.LatLng(latitude, longitude);
        } else {
            center = new window.google.maps.LatLng(latitude + offsetLat, longitude);
        }

        map.setCenter(center);

        createMarker(markerImage, latitude - 0.0004, longitude);
    }

    $(document).ready(function () {
        setupSimpleMap();
    });


    $(document).on("jsonLoaded", function () {
        if ($(".js-map-full-filtering").length > 0) {
            $('html').addClass('no-hardware-acceleration'); // Remove hardware-performance, to allow animations, positioning and map
        }
        setupSimpleMap();
    });
})();