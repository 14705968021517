var Tivoli = Tivoli || {};
Tivoli.Shop = Tivoli.Shop || {};

Tivoli.Shop.Product = function () {
   
    function toggleOption(option, show) {
        option.toggle(show);
        if (show) {
            if (option.parent('span.toggleOption').length)
                option.unwrap();
        } else {
            if (option.parent('span.toggleOption').length == 0)
                option.wrap('<span class="toggleOption" style="display: none;" />');
        }
    };

    function init() {


        handleDateButton();
        handleBuyProduct();
        handleQuantitySelectionForRelatedProducts();
        handleChooseSubProduct();
    }

    function handleDateButton() {
        $.each($(".js-date-button"), function() {
            var dateButton = $(this);
            dateButton.on("click", function (e) {
                e.preventDefault();
                var productLink = dateButton.attr("href");
                var productQuantity = dateButton.closest(".js-buy-container").find("select").val();
                if($(this).hasClass("js-grouped-date-button")) {
                    productQuantity = dateButton.parents(".js-buy-container").find(".js-product-quantity").val();
                }
                window.location = productLink + "?quantity=" + productQuantity;
            });
        });
    }


    function handleQuantitySelectionForRelatedProducts() {
        $.each($(".js-buy-container"), function () {
            var products = $(this).find(".js-product").andSelf().filter(".js-product");
            var quantity = Tivoli.getUrlParameterByName("quantity");
            var mainProduct = null;
            var relatedProducts = [];

            // Find main product and related products
            $.each(products, function() {
                var product = $(this);
                if (product.data("type") === "related") {
                    relatedProducts.push(createProductObject(product));
                } else {
                    mainProduct = createProductObject(product);
                    if (quantity != "") {
                        mainProduct.ddl.val(quantity);
                    }
                }
            });

            function onChange() {
                handleChange($(this));
            }
            
            function handleChange($elem) {
                var selectionStr = $elem.val();
                var selection = parseInt(selectionStr);
                for (var i = 0; i < relatedProducts.length; i++) {
                    var relProd = relatedProducts[i];

                    // If value for related product is higher than main product - reduce value to same as main product
                    var relSelection = parseInt(relProd.ddl.val());
                    if (relSelection > selection) {
                        relProd.ddl.val(selectionStr);
                    }

                    // Hide options with values above main selection
                    $.each(relProd.ddl.find("option"), function () {
                        var option = $(this);

                        if (parseInt(option.attr("value"), 10) > selection) {
                            // Beware: For this to work, jquery.selectric.js has been modified line 188-190
                            toggleOption(option, false);
                        } else {
                            toggleOption(option, true);
                        }
                    });

                    // Needed to be called twice in order for refresh to work properly
                    relProd.ddl.selectric('refresh');
                    relProd.ddl.selectric('refresh');
                }
            }

            mainProduct.ddl.on('change', onChange);
            mainProduct.ddl.selectric({
                onChange: onChange
            });

            handleChange(mainProduct.ddl);
        });
    }
    
    function createProductObject($product) {
        return {
            dom: $product,
            ddl: $product.find(".js-product-quantity").first()
        };
    }

    function handleChooseSubProduct() {
        var selects = $(".js-product-select");
        if (selects.length === 0) {
            return;
        }

        var onChange = function (select) {
            var product = select.parents(".js-product");
            if (!product.hasClass("js-is-product-group")) {
                return;
            }

            var container = select.parents(".js-product-container");
            var subTitles = container.find(".js-product-subtitle");
            var selectedId = select.val();

            //Google analytics product data
            var gaName = select.find('option:selected').data("ga-product-name");
            var gaId = select.find('option:selected').data("ga-product-id");
            var gaCategory = select.find('option:selected').data("ga-product-category");
            var gaPrice = select.find('option:selected').data("ga-product-price");
            var gaDetailView = product.data('ga-detail-view');
            
            var productRequiresDate  = select.find('option:selected').data('product-specific-date');
            var productLink  = select.find('option:selected').data('product-link');
            

            

            // Update productId
            product.data("product-id", selectedId);

            //Update product with correct ga data
            product.data("ga-product-name", gaName);
            product.data("ga-product-id", gaId);
            product.data("ga-product-price", gaPrice);
            product.data("ga-product-category", gaCategory);
            product.data('ga-detail-view', gaDetailView);

            // Show/hide subtitles
            subTitles.each(function() {
                if ($(this).data("id") === selectedId) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });

            // Update buy button disabled + toggle product-quantity-visibility
            var button = container.find(".js-buy-button");
            var datespecificBuyButton = container.find('.js-grouped-date-button')
            var quantitySelector = container.find(".js-product-quantity-container");
            
            //Handle datespecific 
            if(productRequiresDate === "True") {
                button.hide();
                datespecificBuyButton.show();
                datespecificBuyButton.attr('href', productLink);
            } else {
                button.show();
                datespecificBuyButton.hide();
            }

            if (selectedId) {
                button.removeClass("disabled");
                quantitySelector.removeClass("product-quantity-hidden");
                container.addClass("product-container--forced-open");
                
            } else {
                button.addClass("disabled");
                quantitySelector.addClass("product-quantity-hidden");
				container.removeClass("product-container--forced-open");
            }
        };

        selects.on("change", function() {
            onChange($(this));
        });

        selects.each(function () {
            onChange($(this));
        });
    }

    function handleBuyProduct() {
        $.each($(".js-buy-button"), function () {
            var buttonState = {
                errorTimeout: null
            };
            var buyButton = $(this);
            buyButton.on("click", function (e) {
                e.preventDefault();

                if (buyButton.hasClass("disable")) {
                    return;
                }
                
                var loading = buyButton.data("loading");
                if (loading) {
                    return;
                }

                // The buy container contains all the products that are part of a purchase, by clicking the buy button
                var buyContainer = $(this).parents(".js-buy-container");
                if (buyContainer.length === 0) {
                    return;
                }
                if (buyContainer.length > 1) {
                    buyContainer = buyContainer.first();
                }

                var products = buyContainer.find(".js-product").andSelf().filter(".js-product");
                if (products.length === 0) {
                    return;
                }

                // Only one related product supported atm.
                var mainProd;
                var relatedProd;
                var relatedProducts = [];
                var googleAnalyticsProdutPage = "";
                var googleAnalyticsProducts = [];
                $.each(products, function() {
                    var product = $(this);
                    if (product.data("type") === "related") {
                        relatedProducts.push(product);
                    } else {
                        mainProd = product;
                    }
                });

                if (!mainProd) {
                    return;
                }

                if (!mainProd.data("product-id")) {
                    return;
                }
                
                buyButton.data("loading", true);
                buyButton.addClass("loading");
                
                removeErrorMessage(buyButton, buttonState);

                var requestData = {};
                requestData.CurrentItemPath = Tivoli.getCurrentItemPath();

                requestData.MainProduct = {
                    ProductId: mainProd.data("product-id"), Quantity: mainProd.find(".js-product-quantity").val()
                };

                var gaMainProduct = {
                    'name' : mainProd.data('ga-product-name'),
                    'id' : mainProd.data('ga-product-id'),
                    'price': mainProd.data('ga-product-price'),
                    'category': mainProd.data('ga-product-category'),
                    'quantity': requestData.MainProduct.Quantity
                }
                googleAnalyticsProdutPage = mainProd.data('ga-detail-view');

                googleAnalyticsProducts.push(gaMainProduct);

                var mainProductDatePicker = mainProd.find(".js-product-date").siblings('.datepicker-simple-inner');
                if (mainProductDatePicker.length > 0) {
                    var mainProductDate = mainProductDatePicker.datepicker("getDate");
                    if (mainProductDate) {
                        requestData.MainProduct.Date = Tivoli.dateUtil.formatLocalDate(mainProductDate);
                    }
                }
                requestData.RelatedProducts = [];
                if(relatedProducts.length > 0) {
                    $.each( relatedProducts, function( index, value ){
                        var relatedProduct = {
                            ProductId: value.data("product-id"), 
                            Quantity: value.find(".js-product-quantity").val()
                        }
                        requestData.RelatedProducts.push(relatedProduct);
                        
                        var gaRelatedProduct = {
                            'name' : value.data('ga-product-name'),
                            'id' : value.data('ga-product-id'),
                            'price': value.data('ga-product-price'),
                            'category': value.data('ga-product-category'),
                            'quantity': relatedProduct.Quantity
                        }
                
                        googleAnalyticsProducts.push(gaRelatedProduct);
                    });
                }
                

                var loader = buyButton.find(".js-circle-loader");
                Tivoli.CircleLoader.loadInitial((loader), null);

                // Add in-progress class to button
                buyButton.addClass('button--in-progress');

                $.ajax({
                    url: "/WebShop/Basket/BuyProduct",
                    traditional: true,
                    data: JSON.stringify({ dto: requestData }),
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    error: function (err) {
                        Tivoli.CircleLoader.reset(loader);
                        showFailureIcon(buyButton, buttonState);
                        Tivoli.ButtonMessage.showErrorMessage(buyButton, err);
                        loadingEnded(buyButton);
                    },
                    success: function (data) {
                        if (data.Success) {
                            Tivoli.CircleLoader.loadFinish(loader, function() {
                                googleAnalyticsAddToBasketEvent(googleAnalyticsProducts, googleAnalyticsProdutPage);
                                showSuccessIcon(buyButton);
                                window.location.href = data.BasketUrl;

                                // No load finished when going directly to the basket

                                // USED FOR TESTING ERROR
                               /* Tivoli.CircleLoader.reset(loader);
                                showFailureIcon(buyButton, buttonState);
                                Tivoli.ButtonMessage.showErrorMessage(buyButton, "Produktet er udsolgt");*/
                                try{
                                    var trackingUpdate = {
                                        productName : gaMainProduct.name,
                                        productId : gaMainProduct.id,
                                        quantityChange: gaMainProduct.quantity
                                    }
                                    Tivoli.Shop.Tracking.pushDigitalDataCartQuantityUpdate([trackingUpdate]);
                                }catch(err){}
                            });

                        } else {
                            Tivoli.CircleLoader.reset(loader);
                            showFailureIcon(buyButton, buttonState);
                            Tivoli.ButtonMessage.showErrorMessage(buyButton, data.ErrorMessage);
                            loadingEnded(buyButton);
                        }
                        
                    },
                    type: 'POST'
                });
            });

        });
    }
    
    function loadingEnded(buyButton) {
        buyButton.data("loading", false);
        buyButton.removeClass("loading");
    }

    function removeErrorMessage(item, buttonState) {
        if (buttonState.errorTimeout) {
            clearTimeout(buttonState.errorTimeout);
            buttonState.errorTimeout = null;
        }

        item.removeClass('button--in-progress');
        item.find('.icon-basket').fadeIn(300, function () {
            item.find('.icon-error').remove();
        });
        Tivoli.ButtonMessage.removeMessage(item);
        item.find('.icon-basket');
    }

    function showFailureIcon(item, buttonState) {
        item.find('.icon-basket').fadeOut(300, function() {
            $(this).after('<span class="button-error icon-error"></span>');
        });

        buttonState.errorTimeout = setTimeout(function () {
            removeErrorMessage(item, buttonState);
        }, 10000);
    }

    function showSuccessIcon(item) {
        item.find('.icon-basket').fadeOut(300, function() {
            $(this).after('<span class="checkbox-anim"></span>');
        });
    }

    function googleAnalyticsAddToBasketEvent(products, detailView) {
        dataLayer.push({
            event: 'addToCartTivoliDk',
            ecommerce: {
                add: {
                    actionField: {
                        list: detailView, // -> ’produktoversigt’ eller ’produktside’ eller ’kurvsiden’ 
                        dimension4: 'Tivoli.dk', // fast værdi -> skelne mellem tivoli.dk og billetlugen etc.
                    },
                    'products':  products
                }
            }
        });

    }


    $(document).ready(function () {
        init();
    });
}();