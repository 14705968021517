var Tivoli = Tivoli || {};

Tivoli.LazyLoading = {
    vars: {
        // Only serve images with following widths
        imgMaxWidth: 1920,
    },
    LazyLoad: function (imgs, bgImages, loadAll) {
       
        if (Tivoli.vars.isMobileDevice) {
            // Begin loading images 200 px before in view on mobile
            imgs.unveil(50, false);
            bgImages.unveil(50, true);
        } else {
            if (loadAll) {
                imgs.unveil(5000, false);
                bgImages.unveil(12000, true);
            } else {
                imgs.unveil(500, false);
                bgImages.unveil(1200, true);
            }
            
        }
    },
    CalculateImageWidth: function (elem) {
        var maxWidth = elem.attr("max-width") || Tivoli.vars.screenMaxWidth,
            // The maximum width of the picture before taking density into account
            maxWidthBeforeDensity = elem.attr("mwbd") || Tivoli.vars.screenMaxWidth,
            widthDesktopInPercent = elem.attr("wd"),
            widthTabletInPercent = elem.attr("wt"),
            widthMobileInPercent = elem.attr("wm"),
            fixedWidth = elem.attr("fw"),
            pixelDensity = Tivoli.vars.devicePixelRatio;

        if (fixedWidth) {
            return fixedWidth;
        }

        widthDesktopInPercent = widthDesktopInPercent || widthTabletInPercent || widthMobileInPercent;
        widthTabletInPercent = widthTabletInPercent || widthDesktopInPercent;
        widthMobileInPercent = widthMobileInPercent || widthTabletInPercent || widthDesktopInPercent;

        var imageMaxWidth,
            imageMaxHeight;
        
        // If desktop
        if (Tivoli.vars.screenWidth > Tivoli.vars.tabletThreshold) {
            imageMaxWidth = widthDesktopInPercent / 100 * Tivoli.vars.screenWidth;
            // If tablet
        } else if (Tivoli.vars.screenWidth > Tivoli.vars.mobileThreshold) {
            imageMaxWidth = widthTabletInPercent / 100 * Tivoli.vars.screenWidth;
            // If mobile
        } else {
            imageMaxWidth = widthMobileInPercent / 100 * Tivoli.vars.screenWidth;
        }

        // If desktop
        if (Tivoli.vars.screenHeight > Tivoli.vars.tabletThreshold) {
            imageMaxHeight = widthDesktopInPercent / 100 * Tivoli.vars.screenHeight;
            // If tablet
        } else if (Tivoli.vars.screenHeight > Tivoli.vars.mobileThreshold) {
            imageMaxHeight = widthTabletInPercent / 100 * Tivoli.vars.screenHeight;
            // If mobile
        } else {
            imageMaxHeight = widthMobileInPercent / 100 * Tivoli.vars.screenHeight;
        }
        
        // Width from percent in pixels
        var widthFromPercent = Math.max(imageMaxWidth, imageMaxHeight);

        // The lowest of width from pixels and the max width
        var bestWidth = Math.min(widthFromPercent, maxWidth);

        bestWidth = Math.min(bestWidth, maxWidthBeforeDensity);

        // Add pixel density to the calculation, but do not server larger than 1920 pictures
        var imgWidth = Math.ceil(Math.min(maxWidth, bestWidth * pixelDensity));

        // if we from the server allow larger images than the premade breakpoints, then serve that
        return imgWidth > maxWidth ? maxWidth : imgWidth;
    },
    CalculateImageExtension: function (elem) {
        if (!elem.attr("wm") && !elem.attr("wt") && !elem.attr("wd") && !elem.attr("fw")) {
            return "";
        }

        var bestWidthInRightDensity = Tivoli.LazyLoading.CalculateImageWidth(elem);

        var resizeParams = {};

        var squared = elem.data("squared"),
            isSquared = !!squared && squared === 1;
        
        resizeParams.w = bestWidthInRightDensity;
        if (isSquared) {
            resizeParams.h = bestWidthInRightDensity;
        }
        
        if (!resizeParams.c) {
            delete resizeParams.c;
        }
        var extension = $.param(resizeParams);
        elem.data("calculated-extension", extension);
        return extension;
    }
};

$(document).ready(function () {
    Tivoli.LazyLoading.LazyLoad($("img.lazy"), $("[data-bg].lazy"), false);
    Tivoli.LazyLoading.LazyLoad($(".box-item-product img.lazy"), $("[data-bg].lazy"), true);
});

$(document).on("jsonLoaded", function() {
    Tivoli.LazyLoading.LazyLoad($("img.lazy"), $("[data-bg].lazy"), false);
});

/*********************************************************************/
/************************  Unveil.js  - extended *********************/
/************** https://github.com/luis-almeida/unveil ***************/
/*********************************************************************/
; (function ($) {
    $.fn.unveil = function (threshold, isBackgroundImages) {

        var $w = $(window),
            //$w = $('#content-container'),
            th = threshold || 0,
            images = this,
            loaded;

        if (isBackgroundImages) {
            //console.log('isBackgroundImages =' + isBackgroundImages);
            // Handle background images
            this.one("unveil", function () {
                var source = this.getAttribute("data-bg");
                var elem = $(this),
                    extension;
                //console.log($(this));
                if (source) {
                    if (!elem.data("calculated-extension")) {
                        extension = Tivoli.LazyLoading.CalculateImageExtension(elem);
                        // console.log(extension);
                    } else {
                        extension = elem.data("calculated-extension");
                    }
                    // LazyIcon class styles the lazy loading icon
                    if (elem.hasClass("lazyIcon")) {
                        elem.removeClass("lazyIcon");
                    }
                    if (source.indexOf("?") !== -1) {
                        extension = "&" + extension;
                    } else {
                        extension = "?" + extension;
                    }
                    elem.css("backgroundImage", "url('" + source + extension + "')").animate({ opacity: 1 }, 250);
                    elem.removeClass("lazy");
                }
            });
        } else {
            // Handle regular images
            this.one("unveil", function () {
                var source = this.getAttribute("data-src");
                var elem = $(this),
                    extension;
                //console.log($(this));
                if (source) {
                    if (!elem.data("calculated-extension")) {
                        extension = Tivoli.LazyLoading.CalculateImageExtension(elem);
                    } else {
                        extension = elem.data("calculated-extension");
                    }
                    // LazyIcon class styles the lazy loading icon
                    if (elem.hasClass("lazyIcon")) {
                        elem.removeClass("lazyIcon");
                    }
                    if (source.indexOf("?") !== -1) {
                        extension = "&" + extension;
                    } else {
                        extension = "?" + extension;
                    }

                    this.setAttribute("src", source + extension);
                    elem.removeClass("lazy");
                }
            });
        }

        function unveil() {
            var inview = images.filter(function () {
                var $e = $(this),
                    wt = $w.scrollTop(),
                    wb = wt + $w.height(),
                    et = $e.offset().top,
                    eb = et + $e.height();

                return eb >= wt - th && et <= wb + th;
            });
            if (inview.length > 0) {
                loaded = inview.trigger("unveil");
                images = images.not(loaded);
            }
        }
        $w.scroll(unveil);
        $w.resize(unveil);
        $w.on("lazyload", unveil);

        unveil();
        return this;
    };
})(window.jQuery || window.Zepto);